@if (isShow && toast.message) {
    <div class="toast-container" [@openClose]="!isShow ? 'open' : 'closed'" [class]="toast.type">
        <div class="content"> 
            <div class="toast-message">{{toast.message}}</div>
            <svg class="notification-icon" (click)="closeToast()">
                <use xlink:href="assets/icon-sprite.svg#close-circle" />
            </svg>
        </div>
       
        <div class="progeress-bar">
            <div class="progress"></div>
        </div>
    </div>
}

