import { Component, OnInit, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ApiService } from '../../_services/api.service';
import { SharedService } from '../../_services/shared.service';
import { MessageType } from '../../_interfaces/entity';
import { ILoginUser } from '../../_interfaces/user';
import { environment } from '../../../environments/environment';
import { FooterComponent } from '../_common/footer/footer.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterLink, FormsModule, FooterComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  currentApplicationVersion = `${environment.appVersion}${environment.env}`;
  isPasswordVisible: boolean = false;
  username: string = '';
  password: string = '';
  router = inject(Router);
  apiService = inject(ApiService);
  sharedService = inject(SharedService);

  ngOnInit(): void {
    this.checkUserAlreadyLoggedin();
  }
  checkUserAlreadyLoggedin() {
    if (this.sharedService.loginUser()?.courtex_jwt) {
      this.router.navigate(['/packages/1']);
    } else {
      const userData: ILoginUser = JSON.parse(
        localStorage.getItem(
          this.sharedService.LOGIN_USER_SESSION_NAME
        ) as string
      ) as ILoginUser;
      if (userData?.courtex_jwt) {
        this.sharedService.loginUser.set(userData);
        localStorage.setItem(
          'formioToken',
          this.sharedService.loginUser()?.formio_jwt
        );
        this.router.navigate(['/packages/1']);
      }
    }
  }
  onSubmit() {
    this.sharedService.showLoading();
    this.sharedService.hideActiveToast();
    this.apiService
      .login('user/auth/login/token', {
        username: this.username,
        password: this.password,
      })
      .subscribe({
        next: (response) => {
          if (response?.success == 'true') {
            this.sharedService.loginUser.set(response.data);
            this.sharedService.filter.set(
              this.sharedService.initializeFilter()
            );
            this.sharedService.logout.next(false);
            localStorage.setItem(
              this.sharedService.LOGIN_USER_SESSION_NAME,
              JSON.stringify(response.data)
            );
            localStorage.setItem(
              'formioToken',
              this.sharedService.loginUser()?.formio_jwt
            );
            if (!this.sharedService.status().length) {
              this.sharedService.setStatusLOV();
            }
            if (!this.sharedService.cancelReasons().length) {
              this.sharedService.setCancelReasonsLOV();
            }
            if (this.sharedService.redirectURL) {
              this.router.navigateByUrl(this.sharedService.redirectURL);
            } else {
              this.router.navigate(['/packages/1']);
            }
          }
        },
        error: (err) => {
          console.log({ err });

          this.sharedService.showToastMessage({
            message: 'Username or password is incorrect. Please try again!',
            type: MessageType.error,
          });
          this.sharedService.hideLoading();
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }
}
