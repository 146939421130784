import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject, OnInit } from '@angular/core';
import { ActivityLog } from '../../../_interfaces/activityLog';
import { MessageType } from '../../../_interfaces/entity';
import { FormatDateTimePipe } from '../../../_pipes/format-date-time.pipe';
import { ApiService } from '../../../_services/api.service';
import { SharedService } from '../../../_services/shared.service';
import { LoadingComponent } from '../loading/loading.component';

@Component({
  selector: 'app-activity-popup',
  standalone: true,
  imports: [CommonModule, FormatDateTimePipe, LoadingComponent],
  providers: [DatePipe, FormatDateTimePipe],
  templateUrl: './activity-popup.component.html',
  styleUrl: './activity-popup.component.scss',
})
export class ActivityPopupComponent implements OnInit {
  @Input() packageId: string = '';
  activityLogs: ActivityLog[] = [];
  @Output() onClose = new EventEmitter<boolean>();
  datePipe = inject(DatePipe);
  formatDateTime = inject(FormatDateTimePipe);
  sharedService = inject(SharedService);
  apiService = inject(ApiService);
  showPopup: boolean = false;

  @Input() set isShowPopup(value) {
    this.activityLogs = [];
    if (value) {
      this.sharedService.showLoading();
      this.apiService
        .get(`package/document/get-by-package/${this.packageId}`)
        .subscribe({
          next: (response) => {
            this.sharedService.hideLoading();
            if (response?.success == 'true') {
              this.showPopup = value;
              this.activityLogs = response.data.activityDTOs;
            }
          },
          error: (err) => {
            this.sharedService.hideLoading();
            this.sharedService.showToastMessage({
              message: err.message || 'Error loading Activity log',
              type: MessageType.error,
            });
          },
          complete: () => {
            this.sharedService.hideLoading();
          },
        });
    }
  }

  get isShowPopup() {
    return this.showPopup;
  }

  ngOnInit() {
    this.sharedService.showLoading();
  }

  closeActivityLog() {
    this.showPopup = false;
    this.onClose.emit(false);
  }

  downloadCsv() {
    if (this.activityLogs && this.activityLogs.length > 0) {
      let rows: string[] = [];
      const head = ['Sl', 'Subject', 'Date and Time', 'User', 'TAT(hh:mm)'];
      rows.push(head.join(','));
      let x = 1;
      console.log(this.activityLogs);

      this.activityLogs.forEach((value) => {
        const row: string[] = [
          x.toString(),
          value.message,
          this.formatDateTime.transform(value.createdDateTime) as string,
          value.createdBy,
          value.tat,
        ];
        x++;
        rows.push(row.join(','));
      });
      console.log({ rows });

      let csvContent = 'data:text/csv;charset=utf-8,' + rows.join('\r\n');
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `activity_log.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
}
