import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedService } from '../../../_services/shared.service';
interface IMenu {
  id: string;
  icon: string;
  title: string;
  href: string;
  name: string;
  isActive: boolean;
  order: number;
  isShow: boolean;
}
@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnInit {
  menus: IMenu[] = [];
  sharedService = inject(SharedService);
  ngOnInit(): void {
    this.menus = [
      {
        id: '1',
        name: 'Dashboard',
        title: 'Dashboard',
        href: 'dashboard',
        icon: 'menu-dashboard',
        isActive: false,
        order: 1,
        isShow: true,
      },
      {
        id: '2',
        name: 'Listing',
        title: 'Listing page',
        href: 'packages/1',
        icon: 'menu-listing',
        isActive: false,
        order: 1,
        isShow: true,
      },
      {
        id: '3',
        name: 'Archived Listing',
        title: 'Archived Packages',
        href: 'archived-packages/1',
        icon: 'archived-listing',
        isActive: false,
        order: 1,
        isShow: true,
      },
      {
        id: '4',
        name: 'My Vault',
        title: 'My Vault',
        href: 'packages/1/userview',
        icon: 'menu-my-listing',
        isActive: false,
        order: 1,
        isShow: true,
      },
      {
        id: '6',
        name: 'Pivot',
        title: 'Pivot',
        href: 'pivot',
        icon: 'pivot-table',
        isActive: false,
        order: 1,
        isShow: false,
      },
      {
        id: '5',
        name: 'Reports',
        title: 'Reports',
        href: 'reports',
        icon: 'menu-report',
        isActive: false,
        order: 1,
        isShow: true,
      },
      {
        id: '7',
        name: 'Settings',
        title: 'Settings',
        href: 'settings',
        icon: 'menu-setting',
        isActive: false,
        order: 1,
        isShow: false,
      }
    ];
  }

  resetFilter() {
    this.sharedService.filter.set(this.sharedService.initializeFilter());
    this.sharedService.packageListPageNumber.set(1);
  }
}
