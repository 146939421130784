<div class="container">
    <div class="list-container">
        @if(sharedService.status().length) {
        <app-listing-header (onFilter)="onFilter()" [coders]="coders" [isFilterApplied]="isFilterApplied"
            [hasNewPackage]="hasNewPackage" (onGetCaseNameRequest)="isShowCasenameSelectPopup=$event" >
        </app-listing-header>
        }
        <div class="list-wrapper">
            <div class="grid">
                <ng-container>
                    <div class="grid-header">Package ID</div>
                    <div class="grid-header">Case name</div>
                    <div class="grid-header">Source</div>
                    <div class="grid-header">Claim Id</div>
                    <div class="grid-header">Status</div>
                    <div class="grid-header">Created date</div>
                    <div class="grid-header">Last updated</div>
                    <div class="grid-header">Aging <small>(hh:mm)</small></div>
                    <div class="grid-header">Username</div>
                    <div class="grid-header">Actions</div>
                </ng-container>

                @for(package of packageList; track package.packageId; let i = $index) {
                <ng-container>
                    <div class="package-wrapper" (click)="onPackageSelect(package)">
                        <div class="status-flag" [ngClass]="getClassNameByStatus(package.status)">
                        </div>
                        <span>{{package.packageId}}</span>
                    </div>
                    <div>{{package.caseName}}</div>
                    <div>{{package.sourceSystem}}</div>
                    <div>{{package.claimId}}</div>
                    <div>{{getStatusValue(package.status)}}</div>
                    <div>{{package.createdDateTime | formatDateTime }}</div>
                    <div>{{package.updatedDateTime | formatDateTime }}</div>
                    <div>{{package.aging || '00:00'}}</div>
                    <div>{{package.coderName}}</div>
                    <div class="cur-pointer text-align actions">
                        <div class="action-buttons">
                        </div>
                        <div #actionElem class="action-wrapper"
                            (click)="toggledIndex === i ? toggledIndex = -1: toggledIndex = i">
                            <svg class="action-icon">
                                <use xlink:href="assets/icon-sprite.svg#action" />
                            </svg>
                            <div *ngIf="toggledIndex === i" class="action-items">
                                <div (click)="openActivityLog(package)">
                                    <svg class="reassign-icon">
                                        <use xlink:href="assets/icon-sprite.svg#activitylog" />
                                    </svg>
                                    Activity log
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </ng-container>
                }
                @empty {
                <div class="no-records">No records</div>
                }
            </div>
            <app-pagination [totalPackageLength]="totalPackageLength" [pageSize]="pageSize"
                [listCount]="packageList.length" (loadPageEmit)="paginationNavigation($event)"></app-pagination>
        </div>
    </div>
</div>
<app-activity-popup (onClose)="isShowActivityLog = $event" [packageId]="selectedPackageId"
    [isShowPopup]="isShowActivityLog"></app-activity-popup>

<div #assignPopup class="overlay" (click)="closeAssignPopup()" *ngIf="isShowAssign">
    <div class="popup assign-popup" (click)="$event.stopPropagation()">
        <div>Assign</div>
        <div class="close" (click)="closeAssignPopup()">&times;</div>
        <div class="content"> 
            <app-dropdown [placeHolder]="selectedPackageForAssign.coderName || 'Select username'" [selected]="selectedAssignTo" [list]="coderList"
                [hasFilter]="true" (onSelect)="selectedAssignTo=$event">
            </app-dropdown>

            <div class="error" *ngIf="selectedAssignTo.id == selectedPackageForAssign.coderId">The assignment of the
                package to
                the current user is not possible. Please select an alternative username.
            </div>
            <textarea placeholder="Enter your comments here" class="input" rows="6" [(ngModel)]="assignedToComment"
                maxlength="200"></textarea>
            <div class="btn-wrapper d-flex">
                <button class="btn btn-6" (click)="closeAssignPopup()">Cancel</button>
                <button class="btn btn-3" (click)="assignCoder()">Assign</button>
            </div>
        </div>
    </div>
</div>

<div #concurrentPopup class="overlay" (click)="closeConcurrentPopup()" *ngIf="isConcurrentUse">
    <div class="popup concurrent-popup" (click)="$event.stopPropagation()">
        <div class="close" (click)="closeConcurrentPopup()">&times;</div>
        <div class="content">
            <div class="warning">
                <svg>
                    <use xlink:href="assets/icon-sprite.svg#warning" />
                </svg>
                Claim or Package is already being accessed by someone else. Please choose a different one.
            </div>
        </div>
    </div>
</div>

<app-confirm-popup *ngIf="reopenPackageConfirmation" [heading]="'Confirmation'"
    [message]="'Would you like to reopen the cancelled package?'" (yes)="reopenPackage();"
    (no)="reopenPackageConfirmation = null;">
</app-confirm-popup>


<app-confirm-popup *ngIf="isShowUnassignPopup" [heading]="'Confirmation'"
    [message]="'Would you like to unassign the package associated with your name?'" (yes)="unassignYes();"
    (no)="isShowUnassignPopup = false;">
</app-confirm-popup>

<app-case-select-popup *ngIf="isShowCasenameSelectPopup" [selectedCase]="{id:selectedCaseName, value:selectedCaseName}" (onCaseSelect)="onCaseSelect($event)"></app-case-select-popup>