import { CommonModule, DatePipe } from '@angular/common';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  inject,
} from '@angular/core';
import {
  IDropdown,
  IPackage,
  PackageList,
} from '../../_interfaces/packageList';
import { ActivityLog } from '../../_interfaces/activityLog';
import { PaginationComponent } from '../_common/pagination/pagination.component';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { ListingHeaderComponent } from './listing-header/listing-header.component';
import { SharedService } from '../../_services/shared.service';
import { ApiService } from '../../_services/api.service';
import { MessageType } from '../../_interfaces/entity';
import { ICoder } from '../../_interfaces/user';
import { ActivityPopupComponent } from '../_common/activity-popup/activity-popup.component';
import { FormsModule } from '@angular/forms';
import { StatusPipe } from '../../_pipes/status.pipe';
import { Subscription } from 'rxjs';
import { FormatDateTimePipe } from '../../_pipes/format-date-time.pipe';
import { ConfirmPopupComponent } from '../_common/confirm-popup/confirm-popup.component';
import { DropdownComponent } from '../_common/dropdown/dropdown.component';

interface IAssignCoder {
  id: string;
  comment: string;
}

@Component({
  selector: 'app-listing',
  standalone: true,
  imports: [
    CommonModule,
    PaginationComponent,
    RouterModule,
    ListingHeaderComponent,
    ActivityPopupComponent,
    FormsModule,
    StatusPipe,
    FormatDateTimePipe,
    ConfirmPopupComponent,
    DropdownComponent,
  ],
  providers: [DatePipe],
  templateUrl: './listing.component.html',
  styleUrl: './listing.component.scss',
})
export class ListingComponent implements OnInit, OnDestroy {
  toggledIndex: number = -1;
  totalPackageLength: number = 0;
  packageList: PackageList[] = [];
  activityLogs: ActivityLog[] = [];
  readonly DEFAULT_PAGE_SIZE = 20;
  pageSize: number = this.DEFAULT_PAGE_SIZE;
  pageSelected: any = 1;
  sharedService = inject(SharedService);
  apiService = inject(ApiService);
  router = inject(Router);
  activatedRouter = inject(ActivatedRoute);
  coders: ICoder[] = [];
  isConcurrentUse: boolean = false;
  isShowAssign: boolean = false;
  selectedPackageForAssign: any;
  routerSubscription: Subscription = new Subscription();
  concurrencySubscription: Subscription = new Subscription();
  reopenPackageConfirmation: any;

  isShowActivityLog: boolean = false;
  datePipe = inject(DatePipe);
  isFilterApplied: boolean = false;
  coderList: IDropdown[] = [];

  selectedAssignTo: IDropdown = { id: '', value: '' };
  assignedToComment: string = '';
  selectedPackageForUnAssign: PackageList;
  isShowUnassignPopup: boolean = false;
  userView: string = '';

  @ViewChildren('actionElem') actionElems!: QueryList<any>;

  @ViewChild(PaginationComponent, { static: false })
  pagination!: PaginationComponent;
  selectedPackageId: string = '';
  hasNewPackage: boolean = false;

  @HostListener('document:click', ['$event', '$event.target']) onDocumentClick(
    event: any,
    targetElement: HTMLElement
  ) {
    if (
      !this.actionElems
        .get(this.toggledIndex)
        ?.nativeElement.contains(targetElement)
    ) {
      this.toggledIndex = -1;
    }
  }

  constructor() {
    this.selectedAssignTo = {
      value: '',
      id: '',
    };
    this.assignedToComment = '';
    this.selectedPackageForUnAssign = this.initializePackageList();
  }

  initializePackageList(): PackageList {
    return {
      aging: '',
      caseId: '',
      caseName: '',
      claimId: '',
      coderId: '',
      coderName: '',
      createdBy: '',
      createdDateTime: '',
      id: '',
      isClassified: false,
      lastUpdatedBy: '',
      packageId: '',
      percentageDocExtraction: 0,
      percentageOfDocClassification: 0,
      sourceSystem: '',
      status: '',
      timeForDocClassification: '',
      timeForDocExtraction: '',
      updatedBy: '',
      updatedDateTime: '',
    };
  }
  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.concurrencySubscription.unsubscribe();
  }

  ngOnInit() {
    if (this.router.url.endsWith('userview')) {
      this.userView = 'userview';
      this.sharedService.userview.set(this.userView);

      this.sharedService.filter.update((f) => {
        f.coderId = {
          id:
            (this.sharedService.loginUser().user.id as unknown as string) || '',
          value:
            (this.sharedService.loginUser().user.id as unknown as string) || '',
        };
        return f;
      });
    } else {
      this.sharedService.userview.set('');
    }

    this.pageSelected =
      this.activatedRouter.snapshot.paramMap.get('pageNo') || 1;
    // this.getStatusLOV();
    // this.getCancelReasonsLOV();
    this.loadPage(this.pageSelected);
    if (!this.coders?.length) {
      this.getCoders();
    }

    this.concurrencySubscription = this.sharedService.showConcurrency.subscribe(
      (val) => {
        if (val) {
          this.isConcurrentUse = true;
        } else {
          this.isConcurrentUse = false;
        }
      }
    );
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log({ val });
        this.pageSelected =
          this.activatedRouter.snapshot.paramMap.get('pageNo') || 1;
        this.loadPage(this.pageSelected);
      }
    });
  }

  getCoders() {
    this.apiService.get('user/list').subscribe({
      next: (response) => {
        if (response?.success == 'true') {
          this.coders = response.data;
          if (this.coders?.length) {
            this.createCoderList();
          }
        }
      },
      error: (err) => {
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
      },
      complete: () => {},
    });
  }

  createCoderList() {
    this.coderList = [];
    this.coders.forEach((coder) => {
      if (this.selectedPackageForAssign?.coderId != coder.id) {
        this.coderList.push({
          id: coder.id,
          value: coder.firstName + '' + coder.lastName,
        });
      }
    });
  }

  getClassNameByStatus(status: String) {
    return status?.toLowerCase() + '_flag';
  }

  loadPage(pageNumber: any) {
    console.log(this.sharedService.filter());

    this.sharedService.filter.update((f) => {
      f.startIndex = pageNumber ? pageNumber - 1 : 0;
      return f;
    });
    this.sharedService.showLoading();
    const url = `package?${this.getQueryString()}`;
    this.apiService.get(url).subscribe({
      complete: () => {
        this.sharedService.hideLoading();
      },
      error: (err) => {
        this.sharedService.hideLoading();
        console.log({ err });
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
      },
      next: (response) => {
        if (response && response.success == 'true') {
          this.packageList = response.data.data;
          console.log(this.packageList);
          this.calculateAging();
          this.totalPackageLength = response.data.totalRecords; // total records
          this.hasNewPackage = response.data.hasNewPackage;
          /*  if (this.pageSize > this.totalPackageLength) {
             this.pageSize = this.totalPackageLength;
           } else {
             this.pageSize = this.DEFAULT_PAGE_SIZE;
           } */
          //this.pageSize = 5;
        }
      },
    });
  }

  calculateAging() {
    if (this.packageList?.length) {
      this.packageList.forEach((packageVal) => {
        if (!packageVal.createdDateTime || !packageVal.updatedDateTime) return;
        let dateObj = new Date(packageVal.createdDateTime + ' UTC');
        const startDate = new Date(dateObj);

        let endDate = new Date();
        if (packageVal.status === 'COMPLETED') {
          let dateObj = new Date(packageVal.updatedDateTime + ' UTC');
          endDate = new Date(dateObj);
        }

        const differenceInMilliseconds =
          endDate.getTime() - startDate.getTime();
        packageVal.aging = this.millisecondsToHumanReadable(
          differenceInMilliseconds
        );
      });
    }
  }

  millisecondsToHumanReadable(milliseconds: number): string {
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (minutes < 60) {
      return `00:${minutes.toString().padStart(2, '0')}`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours.toString().padStart(2, '0')}:${remainingMinutes
      .toString()
      .padStart(2, '0')}`;
  }

  openActivityLog(packageItem: PackageList) {
    this.isShowActivityLog = true;
    this.selectedPackageId = packageItem.id;
  }

  onFilter() {
    this.pagination.pageSelected = 1;
    this.loadPage(0);
  }

  getQueryString() {
    this.sharedService.filter().pageSize = this.pageSize;
    let queryArr = [];
    for (const [key, value] of Object.entries(this.sharedService.filter())) {
      if (key === 'status' || key === 'coderId') {
        if (value?.id) {
          queryArr.push(`${key}=${value?.id}`);
        }
      } else if (value || key === 'startIndex') {
        queryArr.push(`${key}=${value}`);
      }
    }
    this.isFilterApplied = queryArr.length > 2;
    return queryArr.join('&');
  }

  showAssignPopup(packageItem: PackageList) {
    this.isShowAssign = true;
    this.selectedPackageForAssign = packageItem;
    this.createCoderList();
  }

  closeAssignPopup() {
    this.isShowAssign = false;
  }

  getCoderName(id: string) {
    const obj = this.coders.find((item) => item.id == id);
    return obj ? obj.firstName + '' + obj.lastName : '';
  }

  assignCoder() {
    if (this.selectedAssignTo.id == this.selectedPackageForAssign.coderId) {
      return;
    }
    const assignTo = {
      comment: this.assignedToComment,
      id: this.selectedAssignTo.id,
    };
    this.closeAssignPopup();
    this.sharedService.showLoading();
    this.apiService
      .put(`package/${this.selectedPackageForAssign.id}/assign`, assignTo)
      .subscribe({
        next: (response) => {
          if (response.success == 'true') {
            this.loadPage(this.pageSelected);
            this.sharedService.showToastMessage({
              message: `The package has been successfully assigned to ${this.getCoderName(
                assignTo?.id
              )}`,
              type: MessageType.success,
            });
          } else {
            this.sharedService.showToastMessage({
              message:
                response.message ||
                'Assignment of the package has failed. Please retry.',
              type: MessageType.error,
            });
          }
          this.selectedAssignTo = { id: '', value: '' };
        },
        error: (err) => {
          console.log({ err });
          this.sharedService.hideLoading();
          this.sharedService.showToastMessage({
            message: err.message || 'Error',
            type: MessageType.error,
          });
          this.selectedAssignTo = { id: '', value: '' };
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }

  onPackageSelect(packageItem: PackageList, readOnlyMode: boolean = false) {
    if (packageItem.status == 'NOT_PROCESSABLE') {
      this.router.navigate(['/packages', packageItem.id, 'unprocessed']);
    } else {
      let readOnlyModeValue = '';
      if (readOnlyMode === true) {
        readOnlyModeValue = 'readonly';
      }
      if (!packageItem.isClassified) {
        this.router.navigate([
          '/packages',
          packageItem.id,
          'classification',
          readOnlyModeValue,
        ]);
      } else {
        this.router.navigate([
          '/packages',
          packageItem.id,
          'foi',
          readOnlyModeValue,
        ]);
      }
    }
  }

  closeConcurrentPopup() {
    this.sharedService.hideConcurrencyPopup();
  }
  getStatusValue(statusKey: string): string {
    let statusValue: string = statusKey;
    const index = this.sharedService
      .status()
      .findIndex((e) => e.id == statusKey);
    if (index > -1) {
      statusValue = this.sharedService.status()[index].value;
    }
    return statusValue;
  }
  paginationNavigation(pageno: number) {
    this.sharedService.packageListPageNumber.set(pageno);
    let filterval = this.sharedService.filter();
    filterval.startIndex = pageno;
    this.sharedService.filter.set(filterval);
    console.log({ pageno });
    if (this.sharedService.userview() == 'userview') {
      this.router.navigate(['/packages', pageno, 'userview']);
    } else {
      this.router.navigate(['/packages', pageno]);
    }
  }

  reopenPackage() {
    const packageItem = this.reopenPackageConfirmation;
    console.log(packageItem);
    this.sharedService.showLoading();
    let packageTemp = JSON.parse(JSON.stringify(packageItem));
    delete packageTemp.activityDTOs;
    delete packageTemp.anPackageDocumentDTOs;
    delete packageTemp.anPackageDocumentTypeDTOs;
    packageTemp.status = 'WORK_IN_PROGRESS';
    this.apiService.put('package', packageTemp).subscribe({
      next: (respose) => {
        this.sharedService.showToastMessage({
          message: 'Your package has been reopened successfully',
          type: MessageType.success,
        });
        this.reopenPackageConfirmation = null;
        this.loadPage(this.pageSelected);
      },
      error: (err) => {
        this.sharedService.showToastMessage({
          message: 'Something went wrong',
          type: MessageType.error,
        });
        this.sharedService.hideLoading();
        this.reopenPackageConfirmation = null;
      },
      complete: () => {
        this.sharedService.hideLoading();
        this.reopenPackageConfirmation = null;
      },
    });
  }
  isShowUnassign(packageItem: PackageList): boolean {
    let isShow: boolean = false;

    if (
      packageItem.coderId ==
      (this.sharedService.loginUser().user.id as unknown as string)
    ) {
      if (
        packageItem.status == 'NEW' ||
        packageItem.status == 'WORK_IN_PROGRESS'
      ) {
        isShow = true;
      }
    }
    return isShow;
  }

  unAssign(packageItem: PackageList) {
    this.selectedPackageForUnAssign = packageItem;
    this.isShowUnassignPopup = true;
  }
  unassignYes() {
    this.isShowUnassignPopup = false;
    this.sharedService.showLoading();
    const assignTo = {
      comment: 'Unassigned',
      id: '',
    };
    this.apiService
      .put(`package/${this.selectedPackageForUnAssign.id}/un-assign`)
      .subscribe({
        next: (response) => {
          if (response.success == 'true') {
            this.loadPage(this.pageSelected);
            this.sharedService.showToastMessage({
              message: `The package has been successfully unassigned`,
              type: MessageType.success,
            });
          } else {
            this.sharedService.showToastMessage({
              message:
                response.message ||
                'Unassignment of the package has failed. Please retry.',
              type: MessageType.error,
            });
          }
          this.selectedPackageForUnAssign = this.initializePackageList();
        },
        error: (err) => {
          console.log({ err });
          this.sharedService.hideLoading();
          this.sharedService.showToastMessage({
            message: err.message || 'Error',
            type: MessageType.error,
          });
          this.selectedPackageForUnAssign = this.initializePackageList();
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }
}
