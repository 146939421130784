import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { tokenInterceptor } from './_other/token.interceptor';
import { SharedService } from './_services/shared.service';
import { ApiService } from './_services/api.service';

export function initializeStatus(sharedService: SharedService) {
  return (): Promise<boolean> => sharedService.setStatusLOV();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withFetch()),
    provideAnimations(),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    SharedService,
    ApiService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeStatus,
      deps: [SharedService],
      multi: true,
    },
  ],
};
