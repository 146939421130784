import { Component, OnInit, inject } from '@angular/core';
import { IPackage } from '../../../_interfaces/packageList';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../../../_services/shared.service';
import { ApiService } from '../../../_services/api.service';
import { MessageType } from '../../../_interfaces/entity';
import { FormatDateTimePipe } from '../../../_pipes/format-date-time.pipe';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-unprocessed',
  standalone: true,
  templateUrl: './unprocessed.component.html',
  styleUrl: './unprocessed.component.scss',
  imports: [FormatDateTimePipe],
  providers: [DatePipe],
})
export class UnprocessedComponent implements OnInit {
  package: IPackage;
  packageDBId: string = '';
  activatedRoute = inject(ActivatedRoute);
  sharedService = inject(SharedService);
  apiService = inject(ApiService);

  constructor() {
    this.package = {
      activityDTOs: [],
      anPackageDocumentDTOs: [],
      anPackageDocumentTypeDTOs: [],
      caseId: '',
      caseName: '',
      claimId: '',
      coderId: '',
      coderName: '',
      createdBy: '',
      createdDateTime: '',
      id: '',
      lastUpdateDate: '',
      lastUpdatedBy: '',
      packageId: '',
      percentageDocExtraction: '',
      percentageOfDocClassification: '',
      sourceSystem: '',
      status: '',
      submissionId: '',
      timeForDocClassification: '',
      timeForDocExtraction: '',
      updatedBy: '',
      updatedDateTime: '',
      isClassified: false,
      formioId: '',
      formioName: '',
      page: '',
      packageEvent: '',
    };
  }
  ngOnInit(): void {
    this.packageDBId =
      this.activatedRoute.parent?.snapshot.paramMap.get('packageId') || '';
    this.loadPackage();
  }

  loadPackage() {
    this.sharedService.showLoading();
    this.apiService
      .get(`package/document/get-by-package/${this.packageDBId}`)
      .subscribe({
        next: (response) => {
          this.package = response.data;
          if (this.package.status) {
            this.package.status = this.package.status.toUpperCase();
          }
          console.log(this.package);
        },
        error: (err) => {
          this.sharedService.showToastMessage({
            message: err.message || 'Error',
            type: MessageType.error,
          });
          this.sharedService.hideLoading();
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }
}
