import { Routes } from '@angular/router';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { ListingComponent } from './_components/listing/listing.component';
import { SortingComponent } from './_components/sorting/sorting.component';
import { ClassificationComponent } from './_components/sorting/classification/classification.component';
import { FoiComponent } from './_components/sorting/foi/foi.component';
import { PreviewComponent } from './_components/sorting/preview/preview.component';
import { SettingsComponent } from './_components/settings/settings.component';
import { ReportsComponent } from './_components/reports/reports.component';
import { LoginComponent } from './_components/login/login.component';
import { UnprocessedComponent } from './_components/sorting/unprocessed/unprocessed.component';
import { DcCourtexComponent } from './_components/sorting/courtex/dc-courtex/dc-courtex.component';
import { FoiCourtexComponent } from './_components/sorting/courtex/foi-courtex/foi-courtex.component';
import { PivotComponent } from './_components/pivot/pivot.component';
import { ReportPageComponent } from './_components/reports/report-page/report-page.component';
import { ArchivedListingComponent } from './_components/listing/archived-listing/archived-listing.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'packages/:pageNo',
    component: ListingComponent,
  },
  {
    path: 'packages/:pageNo/userview',
    component: ListingComponent,
  },
  {
    path: 'archived-packages/:pageNo', 
    component: ArchivedListingComponent,
},
{
    path: 'archived-packages/:pageNo/userview',
    component: ArchivedListingComponent,
},
  {
    path: 'dc/:packageId',
    component: DcCourtexComponent,
  },
  {
    path: 'foi/:packageId',
    component: FoiCourtexComponent,
  },
  {
    path: 'packages/:packageId',
    component: SortingComponent,
    children: [
      {
        path: 'classification',
        component: ClassificationComponent,
      },
      {
        path: 'foi',
        component: FoiComponent,
      },
      {
        path: 'unprocessed',
        component: UnprocessedComponent,
      },
      {
        path: 'classification/:readonly',
        component: ClassificationComponent,
      },
      {
        path: 'foi/:readonly',
        component: FoiComponent,
      },
      {
        path: 'preview',
        component: PreviewComponent,
      },
    ],
  },
  {
    path: 'archives/packages/:packageId',
    component: SortingComponent,
    children: [
      {
        path: 'classification',
        component: ClassificationComponent,
      },
      {
        path: 'foi',
        component: FoiComponent,
      },
      {
        path: 'unprocessed',
        component: UnprocessedComponent,
      },
      {
        path: 'classification/:readonly',
        component: ClassificationComponent,
      },
      {
        path: 'foi/:readonly',
        component: FoiComponent,
      },
      {
        path: 'preview',
        component: PreviewComponent,
      },
    ],
  },
  {
    path: 'setting',
    component: SettingsComponent,
  },
  {
    path: 'reports',
    component: ReportsComponent,
  },
  {
    path: 'reports/:report-id',
    component: ReportPageComponent,
  },
  {
    path: 'health',
    component: DashboardComponent,
  },
  {
    path: 'pivot',
    component: PivotComponent,
  },
];
