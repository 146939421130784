export const environment = {
  appVersion: require('../../package.json').version,
  env: ' -- development',
  themeBaseUrl: '',
  production: false,
  // baseUrl: 'http://localhost:8080/api/',
  // baseUrl:
  //   'http://package-service-cluster-dev-alb-1279823278.us-east-1.elb.amazonaws.com/',
  baseUrl: 'https://smartops.smartopsdev.angeiontestsite.com/api/',
  // baseUrl: 'https://so-api.staging.angeiontestsite.com/api/',
  // baseUrlDashboard: 'http://localhost:8000/dashboardApp',
  baseUrlDashboard:
    'https://smartops.smartopsdev.angeiontestsite.com/dashboard-api/dashboardApp',
  xApiKey: '',
  // baseUrl: 'https://so-api.staging.angeiontestsite.com/api/',
  // xApiKey: '8lbpjhzZgJ4mO9GmQ5TsxxJBA6o8IN329SwVagw2',
  // formioUrl:'https://ihniktmcnwsxpbo.form.io/case1',
  formio: {
    baseUrl: 'https://claims-smartops.formio.smartopsdev.angeiontestsite.com/',
    // baseUrl: 'https://claims-staging.formio.staging.angeiontestsite.com/',
    lookupFormName: 'servicefindorcreate',
    noValidate: 'FSvE8ycZVijd2BM6lo28uLMGUF5Dz5',
  },
};
