import { Component, OnInit, inject } from '@angular/core';
import { SharedService } from '../../../_services/shared.service';
import { IToast, MessageType } from '../../../_interfaces/entity';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-toast-message',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toast-message.component.html',
  styleUrl: './toast-message.component.scss',
  animations: [
    trigger('openClose', [
      state('open', style({ transform: 'translateY(100%)', opacity: 0 })),
      state('closed', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('open => closed', [
        animate('200ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition('closed => open', [
        animate('200ms', style({ transform: 'translateY(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class ToastMessageComponent implements OnInit {
  toast: IToast;
  toastSubscription: Subscription = new Subscription();
  toastHideSubscription: Subscription = new Subscription();
  isShow: boolean = false;
  timeout: any;
  DELAY: number = 5000;

  constructor(private sharedService: SharedService) {
    this.toast = {
      message: '',
      type: MessageType.info,
    };
  }

  ngOnInit(): void {
    this.toastSubscription = this.sharedService.toast.subscribe(
      (toast: IToast) => {
        this.toast = toast;
        if (toast && this.toast.message) {
          if (this.toast.message) {
            this.toast = toast;
            if (typeof this.toast.message === 'object') {
              this.toast.message = JSON.stringify(this.toast.message);
            }
            this.isShow = true;
            if (this.timeout) {
              clearTimeout(this.timeout);
            }
            if (!toast.stayActive) {
              this.hideToastAfterSometime();
            }
          }
        }
      }
    );
    this.toastHideSubscription = this.sharedService.hideToast.subscribe(() => {
      this.closeToast();
    })
  }

  hideToastAfterSometime() {
    this.timeout = setTimeout(() => {
      this.closeToast();
    }, this.DELAY);
  }
  closeToast() {
    this.isShow = false;
  }
}
