import { HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { ILoginUser } from '../_interfaces/user';
import { environment } from '../../environments/environment';
import { catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { SharedService } from '../_services/shared.service';
import { Router } from '@angular/router';

export const tokenInterceptor: HttpInterceptorFn = (req, next) => {
  const sharedService = inject(SharedService);
  const router = inject(Router);
  let clonedReq = null;
  const token = (<ILoginUser>(
    JSON.parse(localStorage.getItem('AU_USER') as string)
  ))?.courtex_jwt;
  if (token) {
    clonedReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
        'x-api-key': environment.xApiKey,
      },
    });
    return next(clonedReq).pipe(
      catchError((error) => {
        if ([401].includes(error.status)) {
          sharedService.loginUser.set({
            courtex_jwt: '',
            formio_jwt: '',
            user: {
              first_name: '',
              last_name: '',
              role: '',
              id: 0,
            },
          });
          localStorage.removeItem(sharedService.LOGIN_USER_SESSION_NAME);
          router.navigate(['/login']);
        }
        return throwError(() => error);
      })
    );
  }
  else if (req?.url?.includes('auth/login/token')) {
    return next(req);
  }
  else {
    router.navigate(['/login']);
    return throwError(() => '');
  }

};
