<div #activityPopup class="overlay" (click)="closePopup()">
    <div class="popup" (click)="$event.stopPropagation()">
        <div class="header">Select Case name</div>
        <div class="close" (click)="closePopup()">&times;</div>
        <div class="content">
            <div class="form-group">
                <label for="">Select Case name</label> 
                <app-dropdown [placeHolder]="'Select Case name'" [selected]="selectedCase" [list]="sharedService.caseNames()"
                    (onSelect)="saveCase($event)"></app-dropdown>
            </div>
            <div class="bt-group">
                <button class="btn btn-primary" (click)="closePopup()">Save</button>
            </div>
            <!-- <div class="total-hours">07:30 (hh:mm)</div> -->
        </div>
    </div>
</div>