import { Component, OnInit, inject } from '@angular/core';
import { ClassificationComponent } from '../../classification/classification.component';
import { SharedService } from '../../../../_services/shared.service';

@Component({
  selector: 'app-dc-courtex',
  standalone: true,
  imports: [ClassificationComponent],
  templateUrl: './dc-courtex.component.html',
  styleUrl: './dc-courtex.component.scss',
})
export class DcCourtexComponent implements OnInit {
  sharedService = inject(SharedService);
  ngOnInit(): void {
    this.sharedService.readOnlyMode.set(true);
  }
}
