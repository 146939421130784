<div class="container" (keypress)="resetTimer()" (mousemove)="resetTimer()">
  <div class="head" *ngIf="router.url !== '/login'">
    <app-header></app-header>
  </div>
  <div class="body" *ngIf="router.url !== '/login'">
    <div class="menu">
      <app-menu></app-menu>
    </div>
    <div class="content">
      <router-outlet></router-outlet>
      <app-footer [appVersion]="currentApplicationVersion"></app-footer>
    </div>
  </div>
  <router-outlet *ngIf="router.url === '/login'"></router-outlet>
</div>
<app-loading></app-loading>
<app-toast-message></app-toast-message>