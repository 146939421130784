import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MessageType, SortHeadType } from '../../../../_interfaces/entity';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedService } from '../../../../_services/shared.service';
import { ApiService } from '../../../../_services/api.service';
import { FormsModule } from '@angular/forms';
import { IReasons } from '../../../../_interfaces/cancelReasons';
import { IPackage } from '../../../../_interfaces/packageList';
import { ActivityPopupComponent } from '../../../_common/activity-popup/activity-popup.component';
import { ConfirmPopupComponent } from '../../../_common/confirm-popup/confirm-popup.component';

interface IConfirmationPopupData {
  isShow: boolean;
  message: string;
  title: string;
}

interface ICancelReason {
  reason: string;
  comment: string;
}

@Component({
  selector: 'app-sort-head',
  standalone: true,
  imports: [
    RouterLink,
    CommonModule,
    DatePipe,
    FormsModule,
    ActivityPopupComponent,
    ConfirmPopupComponent,
  ],
  templateUrl: './sort-head.component.html',
  styleUrl: './sort-head.component.scss',
})
export class SortHeadComponent implements OnInit, OnChanges {
  // @Output() onActivitLog = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<boolean>();
  @Output() onNext = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<boolean>();
  @Output() emitSaveCall = new EventEmitter<string>();
  @Output() onBackClick = new EventEmitter<boolean>();
  cancelReason: ICancelReason;
  sharedService = inject(SharedService);
  apiService = inject(ApiService);
  packageDBId: string = '';
  cancelReasons: IReasons[] = [{ id: '', value: '' }];
  @ViewChild('cancelPopup', { static: true })
  cancelPopup!: ElementRef<HTMLElement>;
  isSubmitted: boolean = false;
  isShowActivityLog: boolean = false;
  @Input() headType: SortHeadType = SortHeadType.Classification;
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  datePipe = Inject(DatePipe);
  @Input() isGroupingProgress: boolean = false;
  @Input() imageLeftCount: number = 0;
  @Input() percentageVal: number = 0;
  @Input() isSubmitButtonEnabled: boolean = true;
  timer = { hours: 0, minutes: 0, seconds: 0 };
  pauseTimer: boolean = false;
  isTimerSaved: string = '';
  backButtonConfirmation: IConfirmationPopupData;
  pauseButtonConfirmation: IConfirmationPopupData;
  nextButtonConfirmation: IConfirmationPopupData;
  submitButtonConfirmation: IConfirmationPopupData;
  isArchived: boolean = false;

  @ViewChild('percentageBox', { static: false })
  percentageBox!: ElementRef<HTMLElement>;

  @Input() set timerSaved(value: string) {
    if (!value || value?.indexOf(':') == -1) {
      value = '00:00:00';
    }
    if (value && value.indexOf(':') > -1) {
      this.isTimerSaved = value;
      const timer = value.split(':');
      this.timer.hours = parseInt(timer[0]) || 0;
      this.timer.minutes = parseInt(timer[1]) || 0;
      this.timer.seconds = parseInt(timer[2]) || 0;
    }
  }
  @Input() package: IPackage;

  ngOnInit(): void {
    if(this.router.url && this.router.url.includes("archives")) {
      this.isArchived = true; 
     }
    this.packageDBId =
      this.activatedRoute.parent?.snapshot.paramMap.get('packageId') || '';
    this.cancelReasons = this.sharedService.cancelReasons();
    if (this.sharedService.readOnlyMode()) {
      this.pauseTimer = true;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['package']?.currentValue) {
      const user = this.sharedService.loginUser()?.user?.id;
      if (
        this.package &&
        this.package.id &&
        !this.sharedService.readOnlyMode()
      ) {
        if (this.package.coderId && this.package.coderId != user.toString()) {
          const pageNumber = this.sharedService.packageListPageNumber();
          if(!this.isArchived) {
            this.router.navigate([`/packages/${pageNumber}`]);
            this.sharedService.showConcurrencyPopup();
          }
        }
      }
    }
  }
  get timerSaved() {
    return this.isTimerSaved;
  }

  constructor() {
    this.cancelReason = {
      comment: '',
      reason: '',
    };
    this.package = {
      activityDTOs: [],
      anPackageDocumentDTOs: [],
      anPackageDocumentTypeDTOs: [],
      caseId: '',
      caseName: '',
      claimId: '',
      coderId: '',
      coderName: '',
      createdBy: '',
      createdDateTime: '',
      formioId: '',
      formioName: '',
      id: '',
      isClassified: false,
      lastUpdateDate: '',
      lastUpdatedBy: '',
      packageId: '',
      percentageDocExtraction: '',
      percentageOfDocClassification: '',
      sourceSystem: '',
      status: '',
      submissionId: '',
      timeForDocClassification: '',
      timeForDocExtraction: '',
      updatedBy: '',
      updatedDateTime: '',
      page: '',
      packageEvent: '',
    };
    this.backButtonConfirmation = {
      isShow: false,
      message: 'Would you like to return to the previous page?',
      title: 'Confirmation',
    };
    this.pauseButtonConfirmation = {
      isShow: false,
      message: 'Would you like to place the package On-hold?',
      title: 'Confirmation',
    };
    this.nextButtonConfirmation = {
      isShow: false,
      message: 'Would you like to proceed to the next page?',
      title: 'Confirmation',
    };
    this.submitButtonConfirmation = {
      isShow: false,
      message: 'Would you like to proceed to submit?',
      title: 'Confirmation',
    };

    setInterval(() => {
      if (
        !this.pauseTimer &&
        this.package.packageId &&
        this.package?.status != 'CANCELLED' &&
        this.package?.status != 'COMPLETED'
      ) {
        if (this.timer.seconds > 58) {
          this.timer.seconds = 0;
          this.timer.minutes++;
        } else {
          this.timer.seconds++;
        }
        if (this.timer.minutes > 58) {
          this.timer.minutes = 0;
          this.timer.hours++;
        }
      }
    }, 1000);
    document.documentElement.style.setProperty('--percentageClip', '0px');
  }

  get percentage(): number {
    return this.percentageVal;
  }

  @Input() set percentage(value: number) {
    this.percentageVal = value;
    const clipVal = this.percentageBox
      ? (this.percentageBox.nativeElement.offsetWidth * this.percentage) / 100
      : 0;
    document.documentElement.style.setProperty(
      '--percentageClip',
      (clipVal ? clipVal + 1 : clipVal) + 'px'
    );
  }

  get SortHeadType() {
    return SortHeadType;
  }

  cancelProcess() {
    if (this.isGroupingProgress) return;
    this.cancelPopup.nativeElement.style.display = 'block';
  }

  closeCancel() {
    this.cancelPopup.nativeElement.style.display = 'none';
  }

  onCancelYes() {
    this.isSubmitted = true;
    if (!this.cancelReason.reason) {
      return;
    }
    this.pauseTimer = true;
    this.closeCancel();
    this.sharedService.showLoading();
    this.apiService
      .put(`package/${this.packageDBId}/cancel`, this.cancelReason)
      .subscribe({
        next: (respose) => {
          if (respose.success == 'true') {
            this.emitSaveCall.emit('CANCELLED');
            this.sharedService.showToastMessage({
              message: 'The package has been successfully cancelled',
              type: MessageType.success,
            });
            if (this.sharedService.userview() == 'userview') {
              this.router.navigateByUrl(
                `/packages/${this.sharedService.packageListPageNumber()}/userview`
              );
            } else {
              this.router.navigateByUrl(
                `/packages/${this.sharedService.packageListPageNumber()}`
              );
              // this.router.navigate(['/packages/1']);
            }
          }
        },
        error: (err) => {
          console.log({ err });
          this.sharedService.hideLoading();
          this.sharedService.showToastMessage({
            message: err.message || 'Error',
            type: MessageType.error,
          });
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }
  onSaveClick() {
    if (this.isGroupingProgress) return;
    this.onSave.emit(true);
  }
  onNextClick() {
    this.onNext.emit(true);
  }


  navigateListing() {
    let packageBaseUrl = 'packages';
    if (this.isArchived) {
      packageBaseUrl = 'archived-packages';
    }
    if (this.headType == SortHeadType.FOI) {
      this.onBackClick.emit();
    } else {
      if (this.sharedService.userview() == 'userview') {
        this.router.navigateByUrl(
          `/${packageBaseUrl}/${this.sharedService.packageListPageNumber()}/userview`
        );
      } else {
        this.router.navigateByUrl(
          `/${packageBaseUrl}/${this.sharedService.packageListPageNumber()}`
        );
      }
    }
  }
  navigatePause() {
    this.pauseTimer = true;
    this.sharedService.showLoading();
    this.apiService.put(`package/${this.packageDBId}/hold`).subscribe({
      next: (respose) => {
        if (respose.success == 'true') {
          this.sharedService.showToastMessage({
            message: 'The package has been successfully put On-hold',
            type: MessageType.success,
          });
          this.emitSaveCall.emit('ON_HOLD');
          if (this.sharedService.userview() == 'userview') {
            this.router.navigateByUrl(
              `/packages/${this.sharedService.packageListPageNumber()}/userview`
            );
          } else {
            this.router.navigateByUrl(
              `/packages/${this.sharedService.packageListPageNumber()}`
            );
            // this.router.navigate(['/packages/1']);
          }
        }
      },
      error: (err) => {
        console.log({ err });
        this.sharedService.hideLoading();
        this.sharedService.showToastMessage({
          message: err.message || 'Something went wrong',
          type: MessageType.error,
        });
      },
      complete: () => {
        this.sharedService.hideLoading();
      },
    });
  }
  showActivityLog() {
    //this.onActivitLog.emit(true);
    if (this.isGroupingProgress) return;
    this.isShowActivityLog = true;
  }
  submit() {
    this.onSubmit.emit(true);
  }
  showPauseConfirmation() {
    if (this.isGroupingProgress) return;
    this.pauseButtonConfirmation.isShow = true;
  }
  showBackButtonConfirmation() {
    if (this.isGroupingProgress) return;
    this.backButtonConfirmation.isShow = true;
  }
  showSubmitButtonConfirmation() {
    if (this.isGroupingProgress) return;
    this.submitButtonConfirmation.isShow = true
  }
}
