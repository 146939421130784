<div class="header-container">
    <div class="logo" routerLink="/">UST SmartOps&trade;</div>
    <!-- <svg class="header-logo">
        <use xlink:href="assets/icon-sprite.svg#logo" />
    </svg> -->
    <div class="user-section">
        <svg class="user-icon">
            <use xlink:href="assets/icon-sprite.svg#user-default-img" />
        </svg>
        <div class="d-flex cur-pointer" (click)="showMenu = !showMenu">
            <span>Hello {{sharedService.loginUser().user.first_name || ''}}
                {{sharedService.loginUser().user.last_name || ''}}</span>
            <svg class="arrow-down-icon">
                <use xlink:href="assets/icon-sprite.svg#arrow-down" />
            </svg>
        </div>

        <div class="user-menu" *ngIf="showMenu">
            <div class="d-flex cur-pointer gap-xs" (click)="showConfirmation = true">
                <svg class="arrow-down-icon">
                    <use xlink:href="assets/icon-sprite.svg#logout" />
                </svg>
                Logout
            </div>
        </div>
    </div>
</div>

<app-confirm-popup *ngIf="showConfirmation" [heading]="'Confirmation'" [message]="'Would you like to Log Out ?'"
    (yes)="logout(); showConfirmation = false; showMenu = !showMenu"
    (no)="showConfirmation = false; showMenu = !showMenu"></app-confirm-popup>