<div class="Ang-dashboard-wrapper">
    @if(title) {
    <div class="header-text">{{title}}</div>
    }
    <div class="filter-wrapper">
        <div>
            <div>
                <div class="label">Date range</div>
                <div class="date-range">
                    <input type="date" class="input" [(ngModel)]="dateFrom" (change)="onChangeFromDate($event)" [max]="maxFromDate">
                    <input type="date" class="input" [(ngModel)]="dateTo" [max]="maxDate" [min]="dateFrom">
                </div>
            </div>
        </div>
        @for (list of filterList; track list){
        <div>
            <div class="label">{{list}}</div>
            <select class="input" [(ngModel)]="valueSelected" (ngModelChange)="inputChange(list)">
                <option value="">Select {{filterList}}</option>
                @for (case of uniqueList[list]; track case){
                <option [value]="case">{{case}}</option>
                }
            </select>
        </div>
        }
        <div class="btn-wrapper">
            <button class="btn btn-primary" (click)="applyFilter()">Apply</button>
            <button class="btn btn-secondary" (click)="resetFilter()">Reset</button>
        </div>
    </div>
</div>