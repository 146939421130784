<div class="report-container">
    <div class="rpl-container" appCard>
        <div class="rpl-head">
            <div class="rpl-title">
                Report List
            </div>
        </div>
        <div class="rpl-body">
            <div class="rpl-card" *ngFor="let report of reportLists; let i = index"
                [routerLink]="['/reports', report.id]">
                <div class="rpl-left">
                    <div class="rpl-no">Report {{i + 1}}</div>
                    <div class="rpl-name">{{report.name}}</div>
                </div>
                <div class="rpl-right">
                    <div class="rpl-l1">
                        <div class="rpl-l2">
                            <svg class="round-icon">
                                <use xlink:href="assets/icon-sprite.svg#round-report" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no-report" *ngIf="!reportLists.length">
                No report found
            </div>
        </div>
    </div>
</div>