import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SharedService } from '../../../_services/shared.service';
import { ApiService } from '../../../_services/api.service';
import { MessageType } from '../../../_interfaces/entity';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { IDropdown } from '../../../_interfaces/packageList';

@Component({
  selector: 'app-case-select-popup',
  standalone: true,
  imports: [DropdownComponent],
  templateUrl: './case-select-popup.component.html',
  styleUrl: './case-select-popup.component.scss',
})
export class CaseSelectPopupComponent implements OnInit {
  @Output() onCaseSelect = new EventEmitter<any>();
  sharedService = inject(SharedService);
  apiService = inject(ApiService);
  @Input() selectedCase: IDropdown = { id: '', value: '' };

  ngOnInit(): void {
    this.getCaseNames();
  }
  getCaseNames() {
    this.sharedService.showLoading();
    this.apiService.get('user/cases').subscribe({
      next: (response) => {
        this.sharedService.hideLoading();
        if (response?.success == 'true') {
          const caseNames = response.data.map((item: string) => {
            const caseName: IDropdown = {
              id: item,
              value: item,
            };
            return caseName;
          });
          this.sharedService.caseNames.set(caseNames);
        }
      },
      error: (err) => {
        this.sharedService.hideLoading();
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
      },
      complete: () => {},
    });
  }
  saveCase(caseName: IDropdown) {
    if (caseName) {
      this.selectedCase = caseName;
    }
  }
  closePopup() {
    this.onCaseSelect.emit(this.selectedCase);
  }
}
