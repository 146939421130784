import { Injectable, inject, signal } from '@angular/core';
import { IToast, MessageType } from '../_interfaces/entity';
import { BehaviorSubject } from 'rxjs';
import { IFilter, IPackage } from '../_interfaces/packageList';
import { ILoginUser } from '../_interfaces/user';
import { IStatus } from '../_interfaces/status';
import { IReasons } from '../_interfaces/cancelReasons';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  toast = new BehaviorSubject<IToast>({ message: '', type: MessageType.info });
  hideToast = new BehaviorSubject<boolean>(false);
  loading = new BehaviorSubject(false);
  showConcurrency = new BehaviorSubject(false);
  logout = new BehaviorSubject(false);
  redirectURL: string = '';
  apiService = inject(ApiService);
  loginUser = signal<ILoginUser>({
    courtex_jwt: '',
    formio_jwt: '',
    user: { first_name: '', last_name: '', role: '', id: 0 },
  });
  status = signal<IStatus[]>([]);
  userview = signal<string>('');
  cancelReasons = signal<IReasons[]>([
    {
      id: '',
      value: '',
    },
  ]);
  filter = signal<IFilter>(this.initializeFilter());
  selectedPackage = signal<IPackage>({
    activityDTOs: [],
    anPackageDocumentDTOs: [],
    anPackageDocumentTypeDTOs: [],
    caseId: '',
    caseName: '',
    claimId: '',
    coderId: '',
    coderName: '',
    createdBy: '',
    createdDateTime: '',
    id: '',
    lastUpdateDate: '',
    lastUpdatedBy: '',
    packageId: '',
    percentageDocExtraction: '',
    percentageOfDocClassification: '',
    sourceSystem: '',
    status: '',
    submissionId: '',
    timeForDocClassification: '',
    timeForDocExtraction: '',
    updatedBy: '',
    updatedDateTime: '',
    isClassified: false,
    formioId: '',
    formioName: '',
    page: '',
    packageEvent: '',
  });
  LOGIN_USER_SESSION_NAME = 'AU_USER';
  packageListPageNumber = signal<number>(1);
  readOnlyMode = signal<boolean>(false);

  dashboardSessionStored = signal<boolean>(false);
  dashboardCaseList =  signal<any>([]);
  reportLists = [{ id: '1', name: 'Productivity Report' }, { id: '2', name: 'Cancelled packages' }, { id: '3', name: 'On hold packages ' }, { id: '4', name: 'Unsupported files' }, { id: '5', name: 'Processed packages' }, { id: '6', name: 'All packages' }];


  constructor() {}

  showToastMessage(message: IToast) {
    this.toast.next(message);
  }
  hideActiveToast() {
    this.hideToast.next(true);
  }
  showLoading() {
    this.loading.next(true);
  }
  hideLoading() {
    this.loading.next(false);
  }
  showConcurrencyPopup() {
    this.showConcurrency.next(true);
  }
  hideConcurrencyPopup() {
    this.showConcurrency.next(false);
  }
  initializeFilter(): IFilter {
    return {
      caseName: '',
      createdDateFrom: '',
      createdDateTo: '',
      packageId: '',
      query: '',
      caseId: '',
      claimId: '',
      coderId: { id: '', value: '' },
      coderName: '',
      status: { id: '', value: '' },
      pageSize: 0,
      startIndex: 0,
      sourceSystem: '',
    };
  }

  public setStatusLOV(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (!this.loginUser().courtex_jwt) {
        console.log(this.loginUser());

        resolve(false);
      }
      this.apiService.get('package/common/lov/stage').subscribe({
        next: (response) => {
          if (response?.success && response?.data) {
            this.transformData(response.data); // <-- Potential optimization
            this.status.set(response.data);
          }
          this.setCancelReasonsLOV();
          resolve(true); // Resolve on successful data retrieval
        },
        error: (err) => {
          reject(false); // Reject on error
        },
        complete: () => {
          // No need to resolve here as it's already done in next or error
        },
      });
    });
  }

  transformData(data: IStatus[]): void {
    data.map((element: IStatus) => {
      element.value =
        element.value[0].toUpperCase() + element.value.substr(1).toLowerCase();
    });
  }

  setCancelReasonsLOV(): Promise<boolean> {
    return new Promise((responce, reject) => {
      this.apiService.get('package/common/lov/cancel-reason').subscribe({
        next: (response) => {
          if (response?.success && response?.data) {
            this.cancelReasons.set(response.data);
          }
          responce(true);
        },
        error: (err) => {
          reject(false);
        },
        complete: () => {
          responce(true);
        },
      });
    });
  }
}
