import { DatePipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateTime',
  standalone: true
})
export class FormatDateTimePipe implements PipeTransform {
  datePipe = inject(DatePipe);
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) {
      let dateObj = new Date(value + ' UTC');
      return this.datePipe.transform(dateObj, 'MM/dd/yyyy HH:mm');
    }
    return null;
  }
}
