<div class="list-header d-flex">
    <div class="header-text">@if(sharedService.userview() == "userview") {My Vault } @else {Listing page}</div>
    <div class="header-actions d-flex">
        <button class="btn btn-primary" (click)="getNextPackage()" [disabled]="!hasNewPackage">Get Next Package</button>
        <div class="input search-wrapper">
            <input style="border:none;" id="searchText" type="text" placeholder="Search for case name"
                [(ngModel)]="sharedService.filter().caseName" (keyup.enter)="queryItem()">
            <svg class="search-icon cur-pointer" (click)="queryItem()">
                <use xlink:href="assets/icon-sprite.svg#search" />
            </svg>
        </div>
        <div class="filter-btn-wrapper" #filter>
            <button class="btn btn-secondary d-flex btn-filter" (click)="toggleFilterView()"
                [ngClass]="{'active': toggleFilter != ShowBox.Null || isFilterApplied}">
                <svg class="filter-icon">
                    <use xlink:href="assets/icon-sprite.svg#filter" />
                </svg>
            </button>
            @if(toggleFilter == ShowBox.Filter) {
            <div class="filter-section">
                <div>Filter</div>

                <app-dropdown [placeHolder]="'Select preset'" [selected]="selectedPreset" [list]="filterList"
                    [highlight]=true (onSelect)="onPresetSelect($event)"></app-dropdown>
                <input type="text" class="input" placeholder="Case name"
                    [(ngModel)]="sharedService.filter().caseName" />
                    <input type="text" class="input" placeholder="Claim id"
                        [(ngModel)]="sharedService.filter().claimId" />
                <input type="text" class="input" placeholder="Package id"
                    [(ngModel)]="sharedService.filter().packageId" />
                <input type="text" class="input" placeholder="Source"
                    [(ngModel)]="sharedService.filter().sourceSystem" />
                <div class="date-range">
                    <input type="date" class="input" placeholder="Created date from"
                        [(ngModel)]="sharedService.filter().createdDateFrom">
                    <input type="date" class="input" placeholder="Created date to"
                        [(ngModel)]="sharedService.filter().createdDateTo">
                </div>

                <app-dropdown [placeHolder]="'Select status'" [selected]="selectedStatus" [list]="statusList"
                    (onSelect)="sharedService.filter().status = {id:$event.id, value:$event.value}; selectedStatus=$event">
                </app-dropdown>
                @if(sharedService.userview() != "userview") {
                    <app-dropdown [placeHolder]="'Select user'" [selected]="selectedCoder" [list]="coderDropdown"
                        [hasFilter]="true"
                        (onSelect)="sharedService.filter().coderId = {id:$event.id, value:$event.value}; selectedCoder=$event">
                    </app-dropdown>
                }
                <div class="d-flex">
                    <button class="btn btn-primary w-100" (click)="done()">Filter</button>
                    <button class="btn btn-secondary w-100" (click)="reset()">Reset</button>
                </div>
            </div>
            }
            @else if(toggleFilter == ShowBox.Preset) {
            <div class="filter-section preset">
                <app-preset (onClose)="toggleFilter = ShowBox.Filter" (onSave)="onFilterPresetSave($event)"
                    (onLoad)="loadFilterPreset($event)" [filterPresets]="filterPresets"></app-preset>
            </div>
            }
        </div>

    </div>
</div>