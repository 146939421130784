import { CommonModule, DatePipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { IDropdown } from '../../../_interfaces/packageList';
import { SharedService } from '../../../_services/shared.service';
import { DashboardFilterComponent } from '../../_common/dashboard-filter/dashboard-filter.component';
import { DropdownComponent } from '../../_common/dropdown/dropdown.component';
import { ProductivityReportComponent } from './productivity-report/productivity-report.component';

enum Tab {
  Table = 'table',
  Pivot = 'pivot',
}

@Component({
  selector: 'app-report-page',
  standalone: true,
  imports: [CommonModule, DashboardFilterComponent, FormsModule, DropdownComponent, ProductivityReportComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
  templateUrl: './report-page.component.html',
  styleUrl: './report-page.component.scss'
})


export class ReportPageComponent {
  reportId: string = '';
  sharedService = inject(SharedService);
  activeRoute = inject(ActivatedRoute);
  activeTab: Tab = Tab.Table;
  storageData: any = null;

  definitionBackup!: any;
  dateFrom!: string | null;
  dateTo!: string | null;
  caseName: string = '';
  coderName: string = '';
  datePipe = inject(DatePipe);



  baseURL = environment.baseUrlDashboard;



  pageDefinition = {
    "id": "Angeion",
    "role": "string",
    "users": "user",
    "pageName": "Angeion",
    "clientId": "Angieon_uuid",
    "config": {
      "grid_x": 12,
      "grid_y": 6
    },
    "graphs": [
      {
        "chartId": "chart-10-in build Pivot",
        "title": "",
        "clientId": "Angieon_uuid",
        "source": "angeionData",
        "session_id": "",
        "chart_type": "Pivot",
        "property": {
          "bgColor": "#4abbc1",
          "color": "#25898e",
        },
        "inputs": [{
          "index": [],
          "column_name": [
          ],
          "selected_columns": ['Package Id', 'Case Id', 'Case Name', 'User Name', 'Source System', 'Status', 'Created Date'],
          "filter_pairs": [],
          "aggregate": ['Package Id', 'count', '', 'normal'],
          "global_filters": <any>[]
        }],
        "layout": {
          "position_x": 1,
          "position_y": 1,
          "w": 12,
          "h": 8
        }
      }
    ]
  }

  tableDefinition = {
    "id": "Angeion_table",
    "role": "string",
    "users": "user",
    "pageName": "Angeion",
    "clientId": "Angieon_uuid",
    "config": {
      "grid_x": 12,
      "grid_y": 6
    },
    "graphs": [
      {
        "source": "angeionData",
        "session_id": "",
        "chart_type": "Table",
        "title": "",
        "clientId": "Angieon_uuid",
        "property": {
          "headerBgColor": "#b8c3d4",
          "headerColor": "#2E2E2E",
          "fontSize": "14px",
          "showCount": false,
          "showCountLabel": "",
          "pageSize": 20
        },
        "inputs": [{
          "selected_columns": ['Package Id', 'Case Id', 'Case Name', 'User Name', 'Source System', 'Status', 'Created Date'],
          "filter_pairs": <any>[],
          "global_filters": <any>[],
          "pagination": ''
        }],
        "layout": {
          "position_x": 1,
          "position_y": 1,
          "w": 12,
          "h": 5
        }
      }
    ]
  }

  reportFilterMapping = [{ id: '1', globalFilter: null, filterList: ['User Name'] }, { id: '2', globalFilter: ["Status", "CANCELLED", "="], filterList: ['Case Name'] }, { id: '3', globalFilter: ["Status", "ON_HOLD", "="], filterList: ['Case Name'] }, { id: '4', globalFilter: ["Status", "NOT_PROCESSABLE", "="], filterList: ['Case Name'] }, { id: '5', globalFilter: ["Status", "COMPLETED", "="], filterList: ['Case Name'] }, { id: '6', globalFilter: null, filterList: ['Case Name'] }]
  filterList: string[] = [];
  coderNameList: string[] = [];
  reportName: string = '';

  ngOnInit() {
    this.resetDate();
    let globalFilter = null;
    this.reportId = this.activeRoute.snapshot.paramMap.get(
      'report-id'
    ) as string;

    if (this.reportId) {
      const obj = this.sharedService.reportLists.find(item => item.id == this.reportId);
      this.reportName = obj ? obj.name : '';
    }

    const obj = this.reportFilterMapping.find(item => item.id === this.reportId);
    if (obj) {
      this.filterList = obj.filterList;
      if (obj.globalFilter) {
        globalFilter = obj.globalFilter;

        let definitionDeepCopy = Object.assign({}, this.pageDefinition);
        definitionDeepCopy.graphs[0].inputs[0].global_filters.push(globalFilter);
        this.pageDefinition = definitionDeepCopy;

        let definitionDeepCopy1 = Object.assign({}, this.tableDefinition);
        definitionDeepCopy1.graphs[0].inputs[0].global_filters.push(globalFilter);
        this.tableDefinition = definitionDeepCopy1;
      }
    }

    this.definitionBackup = JSON.parse(JSON.stringify(this.tableDefinition));

    this.storageData = localStorage.getItem('angeionData');

    if (this.reportId !== '1') {
      this.applyFilter();
    }
  }

  public get Tab() {
    return Tab;
  }

  get angeionDataStorage() {
    const sources = localStorage.getItem('sources');
    if (sources) {
      const uniqueCols = JSON.parse(sources).angeionData.unique_columns;
      if (uniqueCols) {
        this.coderNameList = uniqueCols['User Name']['User Name'];
      }
      return JSON.parse(sources).angeionData;
    }
    return null;
  }

  applyFilter(event?: any) {
    if (event) {
      this.dateFrom = event.dateFrom;
      this.dateTo = event.dateTo;
      this.caseName = event['Case Name'];
      this.coderName = event['User Name'];
    }

    this.tableDefinition = JSON.parse(JSON.stringify(this.definitionBackup));
    let definitionDeepCopy = Object.assign({}, this.tableDefinition);
    definitionDeepCopy.graphs.map((graph: any) => {
      if (graph) {
        let filters: (string | string[])[][] = [];
        if (this.dateFrom && this.dateTo) {
          let arr = [];
          arr.push("Created Date");
          arr.push([this.dateFrom, this.dateTo]);
          arr.push("<>");
          filters.push(arr);
        }
        if (this.caseName) {
          let arr = [];
          arr.push("Case Name");
          arr.push([this.caseName]);
          arr.push("=");
          filters.push(arr);
        }
        if (this.coderName) {
          let arr = [];
          arr.push("User Name");
          arr.push([this.coderName]);
          arr.push("=");
          filters.push(arr);
        }

        graph.inputs.forEach((input: any) => {
          if (input.filter_pairs) {
            let filterCopy = JSON.parse(JSON.stringify(filters));
            input.filter_pairs.forEach((item: any) => {
              if (item) {
                filterCopy.push(item);
              }
            })
            input.filter_pairs = [];
            input.filter_pairs = filterCopy;
          }

        })
      }
    });
    this.tableDefinition = definitionDeepCopy; // To make the changedetection works

  }

  resetDate() {
    this.dateTo = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    let dt = new Date();
    dt.setMonth(dt.getMonth() - 3)

    this.dateFrom = this.datePipe.transform(dt, 'yyyy-MM-dd');
    // this.maxDate = this.dateTo;
  }

  resetFilter() {
    this.resetDate();
    this.caseName = '';
    this.coderName = '';
    this.tableDefinition = JSON.parse(JSON.stringify(this.definitionBackup));
    this.applyFilter();
  }

}
