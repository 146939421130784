import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { createWorker } from 'tesseract.js';
import { SharedService } from '../../../../_services/shared.service';
import { CursorMode, MessageType } from '../../../../_interfaces/entity';

@Component({
  selector: 'app-image',
  standalone: true,
  imports: [],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss',
})
export class ImageComponent implements AfterViewInit, OnChanges {
  @Input() selectedImage: string = '';
  @Input() desiredWidth: number = 100;
  @Output() onOutput = new EventEmitter<string>();
  desiredHeight: number = 100;
  tesseractText: string = '';
  @Input() rotate: number = 0;
  @ViewChild('canvas', { static: true }) canvasRef!: ElementRef;
  @Input() zoomLevel: number = 1;
  @Input() cursorMode: CursorMode = CursorMode.EXTRACT;
  cursorStyle: string = 'crosshair';

  ctx!: CanvasRenderingContext2D;
  offscreenCanvas!: HTMLCanvasElement;
  offscreenCtx!: CanvasRenderingContext2D;
  isMouseDown = false;
  startX: number = 0;
  startY: number = 0;
  cropWidth: number = 0;
  cropHeight: number = 0;
  panX: number = 0;
  panY: number = 0;
  sharedService = inject(SharedService);

  ngAfterViewInit(): void {
    this.ctx = this.canvasRef.nativeElement.getContext(
      '2d'
    ) as CanvasRenderingContext2D;
    this.offscreenCanvas = document.createElement('canvas');
    this.offscreenCtx = this.offscreenCanvas.getContext(
      '2d'
    ) as CanvasRenderingContext2D;

    this.updateCursorStyle();
    this.drawImageOnCanvas();
  }
  ngOnChanges(): void {
    this.updateCursorStyle();
    this.drawImageOnCanvas();
  }

  drawImageOnCanvas(dx?: number, dy?: number) {
    
    if (dx) this.panX += dx;
    // else this.panX = 0;
    if (dy) this.panY += dy;
    // else this.panY = 0;
    console.log({ dx }, { dy }, this.panX, this.panY);
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;

    const imageObj = new Image();
    imageObj.crossOrigin = '*';
    //TODO: remove this
    // imageObj.src =
    //   'http://localhost:8080/package/document/get-by-package/download/659d390c9c43b473bb6b08eb/1a161fc4-99c7-43a1-a028-6a56967bed00';
    imageObj.src = this.selectedImage;
    imageObj.onload = () => {
      const aspectRatio = imageObj.width / imageObj.height;
      const desiredHeight = this.desiredWidth / aspectRatio;
      canvas.height = desiredHeight;
      canvas.width = this.desiredWidth;
      // canvas.height = desiredHeight * this.zoomLevel;
      // canvas.width = this.desiredWidth * this.zoomLevel;

      this.offscreenCanvas.width = canvas.width;
      this.offscreenCanvas.height = canvas.height;

      this.offscreenCtx.clearRect(
        0,
        0,
        this.offscreenCanvas.width,
        this.offscreenCanvas.height
      );
      this.offscreenCtx.drawImage(imageObj, 0, 0, canvas.width, canvas.height);

      this.ctx.clearRect(0, 0, canvas.width, canvas.height);
      let canvasWidth = canvas.width * this.zoomLevel;
      let canvasHeight = canvas.height * this.zoomLevel;

      if (this.rotate === 0) {
        //this.ctx.translate(canvas.width, canvas.height);
        this.ctx.rotate(this.rotate);
        //this.ctx.drawImage(this.offscreenCanvas, 0, 0);
        this.ctx.drawImage(
          this.offscreenCanvas,
          this.panX,
          this.panY,
          canvasWidth,
          canvasHeight
        );
      } else if (this.rotate > 3 && this.rotate < 4) {
        this.ctx.save();
        this.ctx.clearRect(0, 0, canvasWidth, canvasHeight);
        this.ctx.translate(canvasWidth, canvasHeight);
        this.ctx.rotate(this.rotate);
        this.ctx.drawImage(
          imageObj,
          -this.panX,
          -this.panY,
          canvasWidth * this.zoomLevel,
          canvasHeight * this.zoomLevel
        );
        this.ctx.restore();
      } else {
        canvas.height = this.desiredWidth;
        this.ctx.translate(canvas.width / 2, canvas.height / 2);
        canvasWidth = -canvas.width / 2;
        canvasHeight = -canvas.height / 2;
        this.ctx.rotate(this.rotate);
        this.ctx.drawImage(
          this.offscreenCanvas,
          canvasWidth,
          canvasHeight,
          canvas.width * this.zoomLevel,
          canvas.height * this.zoomLevel
        );
      }
      this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    };
  }

  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
    this.updateCursorStyle();
    this.startX =
      event.clientX - this.canvasRef.nativeElement.getBoundingClientRect().left;
    this.startY =
      event.clientY - this.canvasRef.nativeElement.getBoundingClientRect().top;
  }

  onMouseMove(event: MouseEvent) {
    if (!this.isMouseDown) return;

    if (this.cursorMode == CursorMode.ZOOM) {
      if (this.zoomLevel > 1) {
        this.drawImageOnCanvas(event.movementX, event.movementY);
      }
      return;
    }

    const currentX =
      event.clientX - this.canvasRef.nativeElement.getBoundingClientRect().left;
    const currentY =
      event.clientY - this.canvasRef.nativeElement.getBoundingClientRect().top;

    this.cropWidth = currentX - this.startX;
    this.cropHeight = currentY - this.startY;

    this.drawCroppedRect();
  }

  onMouseUp(event: MouseEvent) {
    this.isMouseDown = false;
    this.updateCursorStyle();
    if (this.cursorMode == CursorMode.ZOOM) {
      return;
    }
    // Get the base64 representation of the cropped image
    const base64Data = this.getCroppedImageData();
    console.log(base64Data);
    this.imageToText(base64Data);
    this.clearRectangle();
  }

  drawCroppedRect() {
    this.clearRectangle();

    this.ctx.strokeStyle = '#ea6767';
    this.ctx.lineWidth = 2;
    this.ctx.strokeRect(
      this.startX,
      this.startY,
      this.cropWidth,
      this.cropHeight
    );
  }

  clearRectangle() {
    const canvas: HTMLCanvasElement = this.canvasRef.nativeElement;

    this.ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Redraw the original image with adjustments for zoom and other transformations
    let canvasWidth = canvas.width * this.zoomLevel;
    let canvasHeight = canvas.height * this.zoomLevel;

    if (this.rotate === 0) {
      this.ctx.drawImage(
        this.offscreenCanvas,
        this.panX,
        this.panY,
        canvasWidth,
        canvasHeight
      );
    } else {
      this.ctx.translate(canvas.width / 2, canvas.height / 2);
      canvasWidth = -canvas.width / 2;
      canvasHeight = -canvas.height / 2;
      this.ctx.rotate(this.rotate);
      this.ctx.drawImage(
        this.offscreenCanvas,
        canvasWidth,
        canvasHeight,
        canvas.width,
        canvas.height
      );
    }

    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
  }

  getCroppedImageData(): string {
    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext(
      '2d'
    ) as CanvasRenderingContext2D;

    croppedCanvas.width = Math.abs(this.cropWidth);
    croppedCanvas.height = Math.abs(this.cropHeight);

    const x = this.cropWidth < 0 ? this.startX + this.cropWidth : this.startX;
    const y = this.cropHeight < 0 ? this.startY + this.cropHeight : this.startY;

    croppedCtx.drawImage(
      this.canvasRef.nativeElement,
      x,
      y,
      Math.abs(this.cropWidth),
      Math.abs(this.cropHeight),
      0,
      0,
      Math.abs(this.cropWidth),
      Math.abs(this.cropHeight)
    );

    return croppedCanvas.toDataURL('image/jpeg'); // You can use 'image/png' as well
  }

  async imageToText(image: string) {
    this.sharedService.showLoading();
    try {
      const worker = await createWorker('eng');
      const ret = await worker.recognize(image);
      this.tesseractText = ret.data.text;
      await worker.terminate();
      this.sharedService.hideLoading();
    } catch (e) {
      console.log({ e });
      this.sharedService.showToastMessage({
        message: 'Something went wrong. Please try again',
        type: MessageType.info,
      });
      this.sharedService.hideLoading();
    }
    this.onOutput.emit(this.tesseractText);
  }
  toggleCropMode() {
    this.cursorMode = CursorMode.EXTRACT;
    this.resetCropValues();
  }

  resetCropValues() {
    this.startX = 0;
    this.startY = 0;
    this.cropWidth = 0;
    this.cropHeight = 0;
  }
  updateCursorStyle() {
    this.cursorStyle = 'crosshair';
    if (this.cursorMode == CursorMode.ZOOM) {
      this.cursorStyle = 'move';
    }
  }
}
