import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../_services/api.service';
import { SharedService } from '../../_services/shared.service';
import { DashboardFilterComponent } from '../_common/dashboard-filter/dashboard-filter.component';

@Component({
  selector: 'app-pivot',
  standalone: true,
  imports: [DashboardFilterComponent, FormsModule, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './pivot.component.html',
  styleUrl: './pivot.component.scss'
})
export class PivotComponent {
  dateFrom!: string | null;
  dateTo!: string | null;
  caseName: string = '';

  baseURL = environment.baseUrlDashboard;

  @ViewChild(DashboardFilterComponent) filterComp!: DashboardFilterComponent;

  pageDefinition = {
    "id": "Angeion",
    "role": "string",
    "users": "user",
    "pageName": "Angeion",
    "clientId": "Angeion",
    "config": {
      "grid_x": 12,
      "grid_y": 5
    },
    "graphs": [
      {
        "chartId": "chart-10-in build Pivot",
        "title": "",
        "source": "angeionData",
        "session_id": "",
        "chart_type": "Pivot",
        "property": {},
        "inputs": [{
          "index": [],
          "column_name": [
          ],
          "selected_columns": ['Package Id', 'Case Id', 'Case Name', 'User Name', 'Source System', 'Status', 'Created Date', 'Updated By'],
          "column_values": "Package Id",
          "aggfunc": "count",
          "filter_pairs": [],
          "aggregate": ['Package Id', 'count', '', 'normal'],
        }],
        "layout": {
          "position_x": 1,
          "position_y": 1,
          "w": 12,
          "h": 8
        }
      }
    ]
  }
  sharedService = inject(SharedService);
  apiService = inject(ApiService);

  ngOnInit() {
    if (!this.sharedService.dashboardSessionStored()) {
      this.loadAllData();
    }
  }

  loadAllData() {
    this.sharedService.dashboardSessionStored.set(false);
    this.sharedService.showLoading();
    const data = {
      "sourceSet": [
        "angeionData"
      ],
      "client_id": "Angieon_uuid"
    };
    const baseUrl = environment.baseUrlDashboard;
    this.apiService.dashboardPost(baseUrl + '/load_multiple_data', data).subscribe({
      next: (data) => {
        if (data) {
          const uniqueCols = data.sources?.angeionData?.unique_columns;
          if (uniqueCols) {
            this.sharedService.dashboardCaseList.set(uniqueCols['Case Name']['Case Name']);
          }
          if (data.sessions_dict) {
            sessionStorage.setItem('session_dict', JSON.stringify(data.sessions_dict));
            localStorage.setItem('sources', JSON.stringify(data.sources));
            //this.sharedService.isSessionStored.set(true);
          }
          this.sharedService.dashboardSessionStored.set(true);
        }
        this.sharedService.hideLoading();
      },
      error: (err) => {
        console.error('Error fetching data', err);
      },
      complete: () => {
        this.sharedService.hideLoading();
      },
    });
  }

  applyFilter(event?: any) {
    if (event) {
      this.dateFrom = event.dateFrom;
      this.dateTo = event.dateTo;
      this.caseName = event.caseName;
    }
  }

  resetFilter() {
    //this.pageDefinition = JSON.parse(JSON.stringify(this.definitionBackup));
  }

}
