import {
  Component,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SortHeadComponent } from '../_common/sort-head/sort-head.component';
import {
  IDocType,
  MessageType,
  SortHeadType,
} from '../../../_interfaces/entity';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedService } from '../../../_services/shared.service';
import { ApiService } from '../../../_services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { IPackage } from '../../../_interfaces/packageList';
import { ConfirmPopupComponent } from '../../_common/confirm-popup/confirm-popup.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-classification',
  standalone: true,
  imports: [
    SortHeadComponent,
    CommonModule,
    FormsModule,
    ConfirmPopupComponent,
  ],
  providers: [DecimalPipe],
  templateUrl: './classification.component.html',
  styleUrl: './classification.component.scss',
})
export class ClassificationComponent implements OnInit, OnChanges, OnDestroy {
  baseUrl: string = environment.baseUrl;
  //images: IClassificationImage[] = [];
  //doctypes: IDocType[] = [];
  currentRotateDegree: number = 0;
  rotations: number[] = [];
  selectedImages: any = [];
  tempDropImages: any = [];
  groupFileName: string = '';
  groupDocName: any = '';
  groupedImageList: any = [];
  isGroupingProgress: boolean = false;
  draggedItem: any = null;
  isDragBetweenDocs: any = null;
  sharedService = inject(SharedService);
  apiService = inject(ApiService);
  activatedRoute = inject(ActivatedRoute);
  route = inject(Router);
  @ViewChild('sortableList', { static: false })
  sortableList!: ElementRef<HTMLElement>;
  selectedGroup: any;
  imagePreview: boolean = false;
  @Input() isCourtexView: boolean = false;

  @ViewChild(SortHeadComponent, { static: true })
  sortHeadComponent!: SortHeadComponent;

  @ViewChildren('filesList') filesList!: QueryList<any>;
  @ViewChildren('imageList') imageList!: QueryList<any>;
  packageDBId: string = '';

  previewImage: any;
  totalImageCount: number = 0;
  package: IPackage;
  packageDeepCopy: IPackage;
  packageDeepCopyTemp: IPackage;

  percentage: number = 0;
  unClassifiedImageCount: number = 0;
  timerSaved: string = '';
  dropTarget: string = '';
  previewStack: any[] = [];
  moveIndex: number = 0;
  lastImgSelected: string = '';
  preventSaveCall: boolean = false;
  flattenedAnPackageDTOTypes: any = [];
  fileExists: number = 0;
  deleteGroupConfirmation = { group: '', docIndex: -1, fileIndex: -1 };
  deletePageConfirmation = { tempImage: { image: { id: '' } }, i: -1 };
  logoutSubscription: Subscription = new Subscription();

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    if (
      this.percentage?.toString() != this.package.percentageOfDocClassification
    ) {
      this.package.page = '';
      this.onSaveClick(false, false);
      let confirmationMessage = 'Are you sure to leave the page?';
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
    return false;
  }

  constructor(private decimalPipe: DecimalPipe) {
    this.package =
      this.packageDeepCopy =
      this.packageDeepCopyTemp =
        {
          activityDTOs: [],
          anPackageDocumentDTOs: [],
          anPackageDocumentTypeDTOs: [],
          caseId: '',
          caseName: '',
          claimId: '',
          coderId: '',
          coderName: '',
          createdBy: '',
          createdDateTime: '',
          id: '',
          lastUpdateDate: '',
          lastUpdatedBy: '',
          packageId: '',
          percentageDocExtraction: '',
          percentageOfDocClassification: '',
          sourceSystem: '',
          status: '',
          submissionId: '',
          timeForDocClassification: '',
          timeForDocExtraction: '',
          updatedBy: '',
          updatedDateTime: '',
          isClassified: false,
          formioId: '',
          formioName: '',
          page: '',
          packageEvent: '',
        };

    this.logoutSubscription = this.sharedService.logout.subscribe((val) => {
      if (val) {
        if (this.route?.url.includes('classification')) {
          this.onSaveClick(false, false);
          this.preventSaveCall = true;
          this.sharedService.logout.next(false);
        }
      }
    });
  }

  ngOnChanges() {
    this.calculatePercentage();
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.paramMap.get('readonly') == 'readonly') {
      this.sharedService.readOnlyMode.set(true);
      this.sharedService.showToastMessage({
        message: 'You are now in Read Only mode.',
        type: MessageType.info,
      });
    } else {
      if (this.isCourtexView) {
        this.sharedService.readOnlyMode.set(true);
      } else {
        this.sharedService.readOnlyMode.set(false);
      }
    }

    if (this.isCourtexView) {
      this.packageDBId =
        this.activatedRoute.snapshot.paramMap.get('packageId') || '';
    } else {
      this.packageDBId =
        this.activatedRoute.parent?.snapshot.paramMap.get('packageId') || '';
    }
    this.loadPackage();
  }

  loadPackage() {
    this.sharedService.showLoading();
    let getPackageUrl = `package/document/get-by-package/${this.packageDBId}`;
    if (this.isCourtexView) {
      //TODO: update this URL with new
      console.log(atob(this.packageDBId));

      getPackageUrl = `package/get-by-package/${atob(this.packageDBId)}`;
    }
    this.apiService.get(getPackageUrl).subscribe({
      next: (response) => {
        this.package = response.data;
        if (this.package.status) {
          this.package.status = this.package.status.toUpperCase();
        }
        this.totalImageCount = this.package?.anPackageDocumentDTOs?.length | 0;
        this.percentage = this.package.percentageOfDocClassification
          ? parseInt(this.package.percentageOfDocClassification)
          : 0;
        this.timerSaved = this.package.timeForDocClassification;
        if (!this.percentage) {
          this.calculatePercentage();
        } else {
          this.getUnclassifiedImageCount();
        }
        if (
          !this.package.coderId &&
          this.package.status == 'NEW' &&
          !this.sharedService.readOnlyMode()
        ) {
          this.selfAssign();
        }
        if (this.package.status == 'ON_HOLD') {
          this.package.status = 'WORK_IN_PROGRESS';
          this.package.page = '';
          this.onSaveClick(false, false);
        }
        // Save a deep copy
        this.packageDeepCopy = JSON.parse(JSON.stringify(this.package));
        this.packageDeepCopyTemp = JSON.parse(JSON.stringify(this.package));
        this.createFlattenFilesArray();
      },
      error: (err) => {
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
        this.sharedService.hideLoading();
      },
      complete: () => {
        this.sharedService.hideLoading();
      },
    });
  }
  selfAssign() {
    this.apiService.put(`package/${this.package.id}/assign/self`).subscribe({
      next: (respose) => {
        if (respose.success == 'true') {
          this.sharedService.showToastMessage({
            message: `Package assigned to ${
              this.sharedService.loginUser().user.first_name
            } ${this.sharedService.loginUser().user.last_name}`,
            type: MessageType.info,
          });
        }
      },
      error: (err) => {
        console.log({ err });
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
      },
      complete: () => {},
    });
  }

  get SortHeadType() {
    return SortHeadType;
  }

  fileGroups(index: number) {
    return Object.keys(this.package.anPackageDocumentTypeDTOs[index].files);
  }

  docCount(index: number) {
    const files = this.package.anPackageDocumentTypeDTOs[index].files;
    return files?.length || 0;
  }

  selectImage(i: number, event: any, imageId: string) {
    if (
      this.package.status == 'CANCELLED' ||
      this.package.status == 'COMPLETED'
    ) {
      return;
    }

    if (event.shiftKey) {
      const unClassified = this.package.anPackageDocumentDTOs.filter(
        (item) => !item.isClassified
      );
      const start = unClassified.findIndex((item) => item.id == imageId);
      const end = unClassified.findIndex(
        (item) => item.id == this.lastImgSelected
      );

      if (start > -1 && end > -1) {
        const inRange = this.imageList
          .toArray()
          .slice(Math.min(start, end), Math.max(start, end) + 1);
        inRange.forEach((element) => {
          const imageId =
            this.package.anPackageDocumentDTOs[
              parseInt(element.nativeElement.id)
            ].id;
          if (
            element.nativeElement.id &&
            imageId != this.lastImgSelected &&
            this.checkIfImageSelected(
              parseInt(element.nativeElement.id),
              imageId
            ) < 0
          ) {
            this.selectedImages.push({
              index: parseInt(element.nativeElement.id),
              image:
                this.package.anPackageDocumentDTOs[
                  parseInt(element.nativeElement.id)
                ],
            });
          }
        });
        this.lastImgSelected = imageId;
      }
    } else {
      this.lastImgSelected = imageId;
      this.selectImages(i, imageId);
    }
  }

  selectImages(i: number, imageId: string) {
    const index = this.checkIfImageSelected(i, imageId);
    if (index > -1) {
      this.selectedImages.splice(index, 1);
    } else {
      this.selectedImages.push({
        index: i,
        image: this.package.anPackageDocumentDTOs[i],
      });
    }
  }

  checkIfImageSelected(i: number, imageId: string) {
    return this.selectedImages.findIndex(
      (item: any) => item.image.id === imageId
    );
  }

  onChangeDocType() {
    const selectedDocType = this.package.anPackageDocumentTypeDTOs.findIndex(
      (item) => item.id === this.groupDocName
    ); // target doctype

    let targetDocType = this.package.anPackageDocumentTypeDTOs[selectedDocType];

    let groupCount = 1;
    if (this.package.anPackageDocumentTypeDTOs[selectedDocType].files) {
      groupCount =
        this.package.anPackageDocumentTypeDTOs[selectedDocType].files.length +
        1;
    } else {
      this.package.anPackageDocumentTypeDTOs[selectedDocType].files = [];
    }

    this.groupFileName = targetDocType.name + '-' + groupCount + '.pdf';
  }

  handleDocTypeChange(selectedDocType: any) {
    let targetDocType = this.package.anPackageDocumentTypeDTOs[selectedDocType];

    this.package.anPackageDocumentTypeDTOs[selectedDocType].packageUniqueId =
      this.package.id;

    if (this.selectedGroup) {
      this.package.anPackageDocumentTypeDTOs[
        this.selectedGroup.docIndex
      ].files.splice(this.selectedGroup.fileIndex, 1);
      this.package.anPackageDocumentTypeDTOs[selectedDocType].files.push({
        name: this.groupFileName,
        images: this.selectedGroup.group?.images,
      });
    }

    // update document type in documentDTO
    this.selectedGroup.group?.images.forEach((item: any) => {
      if (item) {
        const dtoIndex = this.package.anPackageDocumentDTOs.findIndex(
          (img: any) => img.id == item
        );
        if (dtoIndex > -1) {
          this.package.anPackageDocumentDTOs[dtoIndex].documentType =
            targetDocType.name;
        }
      }
    });

    this.isGroupingProgress = false;
  }

  dragFileToLeft(event: any, i: number) {
    let imageId = '';
    if (event.target.id.startsWith('tempimg_')) {
      const splitted = event.target.id.split('tempimg_');
      imageId = splitted ? splitted[1] : '';
    }
    event.dataTransfer.setData('text', imageId + '#index:' + i);
  }

  dragFileToRight(event: any) {
    event.dataTransfer.setData('text', event.target.id);
  }

  dragGroup(
    event: any,
    group: any,
    docIndex: number,
    docId: any,
    fileIndex: number
  ) {
    this.dragFileToRight(event);
    this.draggedItem = {
      event: event.currentTarget,
      docId,
      docIndex,
      group,
      fileIndex,
    };
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  dropImagesBackToLeft(ev: any) {
    if (
      this.package.status == 'CANCELLED' ||
      this.package.status == 'COMPLETED'
    ) {
      return;
    }

    if (this.sharedService.readOnlyMode()) return;
    ev.preventDefault();
    const data = ev.dataTransfer.getData('text');
    if (data && data.indexOf('#index:') > -1) {
      const splitted = data.split('#index:');
      const tempImg = splitted[0];
      const index = splitted[1];
      if (tempImg && index > -1) {
        this.showDeletePageConfirmation(
          { image: { id: tempImg } },
          parseInt(index)
        );
      }
    }
  }

  drop(ev: any, docId: string) {
    if (this.sharedService.readOnlyMode()) return;
    if (
      this.package?.status == 'CANCELLED' ||
      this.package?.status == 'COMPLETED'
    ) {
      this.sharedService.showToastMessage({
        message: `You are forbidden from performing this action on the ${this.package?.status?.toLowerCase()} package`,
        type: MessageType.info,
      });
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    const data = ev.dataTransfer.getData('text');
    const targetBox = ev ? ev.currentTarget.id : '';

    if (this.draggedItem) {
      // If group drag and drop
      if (targetBox === 'box_' + this.draggedItem.docId) {
        // drag within the doctype
        if (ev.currentTarget.classList.contains('stacked-images')) {
          if (ev.offsetX > ev.target.clientWidth / 2) {
            ev.currentTarget.parentNode.insertBefore(
              this.draggedItem.event,
              ev.currentTarget.nextSibling
            );
          } else {
            ev.currentTarget.parentNode.insertBefore(
              this.draggedItem.event,
              ev.currentTarget
            );
          }
        } else if (ev.currentTarget.parentNode.classList.contains('doc-box')) {
          ev.currentTarget.parentNode.appendChild(this.draggedItem.event);
        }
      } else {
        // drag between the doctypes
        this.handleDragBetweenDoctypes(docId);
      }
    } else {
      // If image drops from left to right
      this.isGroupingProgress = true;
      const imageIndex = data.toString().split('_')[1];

      if (this.selectedImages.length === 0) {
        this.selectedImages.push({
          index: imageIndex,
          image: this.package.anPackageDocumentDTOs[imageIndex],
        });
      }
      this.tempDropImages = this.tempDropImages.concat(this.selectedImages);
      this.removeImagesFromLeft();
      this.groupDocName = docId;
      // check if already existing group
      const dtoTypeIndex = this.package.anPackageDocumentTypeDTOs.findIndex(
        (item) => item.id == docId
      );
      if (
        !this.package.anPackageDocumentTypeDTOs[dtoTypeIndex].files?.find(
          (type) => type.name === this.groupFileName
        )
      ) {
        const selectedDocType =
          this.package.anPackageDocumentTypeDTOs.findIndex(
            (item) => item.id === docId
          ); // target doctype

        const groupCount = this.package.anPackageDocumentTypeDTOs[
          selectedDocType
        ].files
          ? this.package.anPackageDocumentTypeDTOs[selectedDocType].files
              ?.length + 1
          : 1;
        this.groupFileName =
          this.package.anPackageDocumentTypeDTOs[selectedDocType].name +
          '-' +
          groupCount +
          '.pdf';
      }
    }
  }

  handleDragBetweenDoctypes(docId: string) {
    const draggedItem = JSON.parse(JSON.stringify(this.draggedItem));
    const selectedDocType = this.package.anPackageDocumentTypeDTOs.findIndex(
      (item) => item.id === docId
    ); // target doctype

    const targetFilesList =
      this.package.anPackageDocumentTypeDTOs[selectedDocType].files;
    if (!targetFilesList) {
      // initialize to push values
      this.package.anPackageDocumentTypeDTOs[selectedDocType].files = [];
    }

    // push items to the target doctype
    this.package.anPackageDocumentTypeDTOs[selectedDocType].files.push(
      draggedItem.group
    );

    // update document type in documentDTO
    draggedItem.group?.images.forEach((item: any) => {
      if (item) {
        const dtoIndex = this.package.anPackageDocumentDTOs.findIndex(
          (img: any) => img.id == item
        );
        if (dtoIndex > -1) {
          this.package.anPackageDocumentDTOs[dtoIndex].documentType =
            this.package.anPackageDocumentTypeDTOs[selectedDocType].name;
        }
      }
    });

    // empty images
    this.package.anPackageDocumentTypeDTOs[this.draggedItem.docIndex].files[
      this.draggedItem.fileIndex
    ].images = [];

    this.groupDocName = docId;
    this.groupFileName = this.draggedItem.group.name;

    this.createFlattenFilesArray();
    if (this.checkFileNameExists() > 1) {
      this.sharedService.showToastMessage({
        message: 'File name already exists! Please choose a different one....',
        type: MessageType.error,
      });
      this.restorePackage();
    } else {
      this.saveGroupInfo(this.draggedItem.docIndex, false, false, true);
      this.saveGroupInfo(selectedDocType, true, '', true);
    }
  }

  shuffleInPopover(event: any, i: number) {
    event.preventDefault();
    event.stopPropagation();
    const droppedIndex = i;

    if (this.sharedService.readOnlyMode()) return;
    if (!this.draggedItem) {
      // new page to the list. Add it at the end
      const data = event.dataTransfer.getData('text');
      const imageIndex = data?.toString().split('_')[1];

      const isDuplicate = this.checkIfImageSelected(
        imageIndex,
        this.package.anPackageDocumentDTOs[imageIndex].id
      );
      if (isDuplicate < 0) {
        this.selectedImages.push({
          index: imageIndex,
          image: this.package.anPackageDocumentDTOs[imageIndex],
        });
      }
      this.tempDropImages = this.tempDropImages.concat(this.selectedImages);
      this.removeImagesFromLeft();
    } else {
      // Swap images order
      const draggedIndex = this.draggedItem.i;
      const toBeSwapped = this.tempDropImages[draggedIndex];
      this.tempDropImages[draggedIndex] = this.tempDropImages[droppedIndex];
      this.tempDropImages[droppedIndex] = toBeSwapped;
    }
    this.draggedItem = null; //reset
  }

  shuffleInDoctype(event: any, docId: string, j: number) {
    event.preventDefault();
    event.stopPropagation();

    if (this.sharedService.readOnlyMode()) return;

    if (this.draggedItem) {
      // If group drag and drop
      // drag within the doctype
      const draggedIndex = this.draggedItem.fileIndex;
      const droppedIndex = j;
      const toBeSwapped =
        this.package.anPackageDocumentTypeDTOs[this.draggedItem.docIndex].files[
          draggedIndex
        ];
      this.package.anPackageDocumentTypeDTOs[this.draggedItem.docIndex].files[
        draggedIndex
      ] =
        this.package.anPackageDocumentTypeDTOs[this.draggedItem.docIndex].files[
          droppedIndex
        ];
      this.package.anPackageDocumentTypeDTOs[this.draggedItem.docIndex].files[
        droppedIndex
      ] = toBeSwapped;
    }
    this.saveGroupInfo(this.draggedItem.docIndex, false, false, true, false);
    this.draggedItem = null;
  }

  dragWithinPopover(event: any, i: number) {
    this.draggedItem = { event: event.target, i };
    this.dragFileToLeft(event, i);
  }

  removeImagesFromLeft() {
    this.selectedImages.forEach((element: any) => {
      const index = this.package.anPackageDocumentDTOs.findIndex(
        (item) => item.id === element.image.id
      );
      //this.package.anPackageDocumentDTOs.splice(index, 1);
      this.package.anPackageDocumentDTOs[index].isClassified = true;
    });
    this.calculatePercentage();
    this.selectedImages = [];
  }

  groupImages() {
    this.isGroupingProgress = true;
    this.tempDropImages = Object.assign(this.selectedImages);
    this.removeImagesFromLeft();
  }

  saveGroup() {
    if (this.selectedGroup && this.selectedGroup.docIndex > -1) {
      if (this.selectedGroup.docId != this.groupDocName) {
        // handle doctype change here
        const selectedDocType =
          this.package.anPackageDocumentTypeDTOs.findIndex(
            (item) => item.id === this.groupDocName
          ); // target doctype
        this.handleDocTypeChange(selectedDocType);
        this.saveGroupInfo(this.selectedGroup.docIndex);
        this.saveGroupInfo(selectedDocType);
        return;
      }
    }
    this.groupedImageList = [];
    this.tempDropImages.map((item: any) => {
      item.image.isClassified = true;
      item.image.documentType = this.groupDocName;
      this.groupedImageList.push(item.image);
    });
    const selectedDocTypeIndex =
      this.package.anPackageDocumentTypeDTOs.findIndex(
        (item) => item.id === this.groupDocName
      );

    if (!this.package.anPackageDocumentTypeDTOs[selectedDocTypeIndex].files) {
      // reset to empty array before pushing
      this.package.anPackageDocumentTypeDTOs[selectedDocTypeIndex].files = [];
    }

    if (selectedDocTypeIndex > -1) {
      // remove the already existing one
      if (this.selectedGroup) {
        this.package.anPackageDocumentTypeDTOs[
          selectedDocTypeIndex
        ].files.splice(this.selectedGroup.fileIndex, 1);
      }

      this.package.anPackageDocumentTypeDTOs[selectedDocTypeIndex].files.push({
        name: this.groupFileName,
        images: this.groupedImageList.map((e: any) => e.id),
      });

      // Save to API
      this.saveGroupInfo(selectedDocTypeIndex);
    }
  }

  saveGroupInfo(
    selectedDocTypeIndex: number,
    dragBetween?: boolean,
    deletedGroup?: any,
    hideClassifyToast?: boolean,
    groupingProgress?: boolean,
    dragWithinDoctype?: boolean
  ) {
    this.createFlattenFilesArray();
    if (this.checkFileNameExists() > 1) {
      this.sharedService.showToastMessage({
        message: 'File name already exists! Please choose a different one.',
        type: MessageType.error,
      });
      this.restorePackage();
      return;
    }

    this.package.anPackageDocumentTypeDTOs[
      selectedDocTypeIndex
    ].packageUniqueId = this.package.id;

    this.sharedService.showLoading();
    this.apiService
      .put(
        `package/document-type`,
        this.package.anPackageDocumentTypeDTOs[selectedDocTypeIndex]
      )
      .subscribe({
        next: (respose) => {
          if (respose.success == 'true') {
            if (deletedGroup) {
              // Toggle classified field of documentDTO
              deletedGroup.images.forEach((image: string) => {
                const dtoIndex = this.package.anPackageDocumentDTOs.findIndex(
                  (dto) => dto.id === image
                );
                this.package.anPackageDocumentDTOs[dtoIndex].isClassified =
                  false;
              });
            } else if (dragBetween) {
              if (
                this.draggedItem.docIndex > -1 &&
                !this.package.anPackageDocumentTypeDTOs[
                  this.draggedItem.docIndex
                ].files[this.draggedItem.fileIndex]?.images?.length
              ) {
                this.package.anPackageDocumentTypeDTOs[
                  this.draggedItem.docIndex
                ].files.splice(this.draggedItem.fileIndex, 1);
              }
              this.sharedService.showToastMessage({
                message: 'You can change the name of the file if required',
                type: MessageType.info,
              });
              this.draggedItem = null;
            } else if (dragWithinDoctype) {
              this.sharedService.showToastMessage({
                message: 'Your edits have been saved successfully',
                type: MessageType.success,
              });
            }
            if (
              this.selectedGroup &&
              this.selectedGroup.fileIndex > -1 &&
              !this.package.anPackageDocumentTypeDTOs[selectedDocTypeIndex]
                .files[this.selectedGroup.fileIndex]?.images?.length
            ) {
              this.package.anPackageDocumentTypeDTOs[
                selectedDocTypeIndex
              ].files.splice(this.selectedGroup.fileIndex, 1);
            }

            this.getUnclassifiedImageCount();
            if (!this.unClassifiedImageCount && !hideClassifyToast) {
              this.sharedService.showToastMessage({
                message: 'Document grouping, and classification is done.',
                type: MessageType.info,
              });
            } else if (!deletedGroup && !dragBetween && !hideClassifyToast) {
              this.sharedService.showToastMessage({
                message: 'Your file has been saved',
                type: MessageType.success,
              });
            }
            this.calculatePercentage();
            this.packageDeepCopy = JSON.parse(JSON.stringify(this.package));
            //check if the package is already classified
            if (this.package.isClassified && this.unClassifiedImageCount) {
              // classified already. call save API
              this.package.page = '';
              this.onSaveClick();
            }
            this.createFlattenFilesArray();
          }

          this.resetGrouping(groupingProgress);
          this.sharedService.hideLoading();
        },
        error: (err) => {
          this.restorePackage();
          console.log({ err });
          this.sharedService.hideLoading();
          this.resetGrouping(groupingProgress);
          this.sharedService.showToastMessage({
            message: 'Failed to save the edits',
            type: MessageType.error,
          });
        },
        complete: () => {
          this.sharedService.hideLoading();
          this.resetGrouping(groupingProgress);
        },
      });
  }

  restorePackage() {
    // restore the package if API failure
    const tempTime = this.package.timeForDocClassification?.slice(0);
    this.package = JSON.parse(JSON.stringify(this.packageDeepCopy));
    this.calculatePercentage();
    this.package.timeForDocClassification = tempTime;
    /***************************************************** */
  }

  showDeletePageConfirmation(tempImage: any, i: number) {
    if (
      this.package.status == 'CANCELLED' ||
      this.package.status == 'COMPLETED'
    ) {
      return;
    }
    this.deletePageConfirmation = { tempImage, i };
  }

  removeImage() {
    this.tempDropImages[this.deletePageConfirmation.i].image.isClassified =
      false;
    const index = this.package.anPackageDocumentDTOs.findIndex(
      (item) => item.id === this.deletePageConfirmation.tempImage?.image?.id
    );
    if (index > -1) {
      this.package.anPackageDocumentDTOs[index].isClassified = false;
    }
    this.tempDropImages.splice(this.deletePageConfirmation.i, 1);
    this.calculatePercentage();
    if (this.selectedGroup) {
      // Save to API
      const imageToDelete = this.deletePageConfirmation.tempImage?.image?.id;
      let imagesList =
        this.package.anPackageDocumentTypeDTOs[this.selectedGroup.docIndex]
          .files[this.selectedGroup.fileIndex].images;
      imagesList.splice(
        imagesList.findIndex((item) => item === imageToDelete),
        1
      );
      const toBeDeleted = { images: [imageToDelete] };
      this.saveGroupInfo(
        this.selectedGroup.docIndex,
        false,
        toBeDeleted,
        false,
        true
      );
    }
    this.resetDeletePageConfirmation();
    this.draggedItem = null;
  }

  showdeleteGroupConfirmation(
    group: any,
    docIndex: number,
    fileIndex: number,
    event: any
  ) {
    this.deleteGroupConfirmation = { group, docIndex, fileIndex };
    event.stopPropagation();
  }

  deleteGroup() {
    this.sharedService.showLoading();
    const toBeDeleted = JSON.parse(
      JSON.stringify(this.deleteGroupConfirmation.group)
    );
    this.package.anPackageDocumentTypeDTOs[
      this.deleteGroupConfirmation.docIndex
    ].files[this.deleteGroupConfirmation.fileIndex].images = [];

    this.selectedGroup = {
      fileIndex: this.deleteGroupConfirmation.fileIndex,
      group: this.deleteGroupConfirmation.group,
      docIndex: this.deleteGroupConfirmation.docIndex,
    };
    // Save to API
    this.saveGroupInfo(
      this.deleteGroupConfirmation.docIndex,
      false,
      toBeDeleted
    );
    this.resetDeleteGroupConfirmation();
  }

  resetDeleteGroupConfirmation() {
    this.deleteGroupConfirmation = { group: '', docIndex: -1, fileIndex: -1 };
  }

  resetDeletePageConfirmation() {
    this.deletePageConfirmation = { tempImage: { image: { id: '' } }, i: -1 };
  }

  showGrouping(group: any, docIndex: number, docId: string, fileIndex: number) {
    this.loadDropImages(group, docIndex);
    this.isGroupingProgress = true;
    this.selectedGroup = { fileIndex, group, docIndex, docId };
  }

  loadDropImages(group: any, docIndex: number) {
    this.groupDocName = this.package.anPackageDocumentTypeDTOs[docIndex].id;
    this.groupFileName = group.name;
    group.images.forEach((image: any) => {
      const { item, index } = this.findImageFromDocumentDTO(image);
      if (item && parseInt(index) > -1) {
        this.tempDropImages.push({ index: index, image: item });
      }
    });
  }

  findImageFromDocumentDTO(imageId: string) {
    const imageDoc = Object.assign(this.package.anPackageDocumentDTOs);
    let imageDetails = { item: '', index: '' };
    imageDoc.find((item: any, index: any) => {
      if (item.id === imageId) {
        imageDetails = { item, index };
      }
    });
    return imageDetails;
  }

  cancelGrouping() {
    this.isGroupingProgress = false;
    let docIndex = -1;
    let fileIndex = -1;
    let imagesArray: string[] = [];
    if (this.selectedGroup) {
      // existing group
      docIndex = this.selectedGroup.docIndex;
      fileIndex = this.selectedGroup.fileIndex;
    } else {
      // new group
      docIndex = this.package.anPackageDocumentTypeDTOs.findIndex(
        (item) => item.id === this.groupDocName
      );
    }

    this.tempDropImages.forEach((element: any) => {
      imagesArray.push(element.image.id);
      // delete only the ungrouped ones in case of saved group
      if (docIndex > -1 && fileIndex > -1) {
        if (
          !this.package.anPackageDocumentTypeDTOs[docIndex].files[
            fileIndex
          ].images.includes(element.image.id)
        ) {
          this.package.anPackageDocumentDTOs[element.index].documentType = '';
          this.package.anPackageDocumentDTOs[element.index].isClassified =
            false;
        }
      } else {
        this.package.anPackageDocumentDTOs[element.index].documentType = '';
        this.package.anPackageDocumentDTOs[element.index].isClassified = false;
      }
    });
    if (this.selectedGroup) {
      this.checkIfAnyUnsaved(imagesArray, docIndex);
    }
    this.resetGrouping();
    this.calculatePercentage();
  }

  checkIfAnyUnsaved(imagesArray: string[], docIndex: number) {
    const changedGroup = { name: this.groupFileName, images: imagesArray };
    if (
      JSON.stringify(changedGroup) !=
        JSON.stringify(this.selectedGroup.group) ||
      this.package.anPackageDocumentTypeDTOs[docIndex].id != this.groupDocName
    ) {
      this.sharedService.showToastMessage({
        message: 'Your changes have been cancelled',
        type: MessageType.info,
      });
    }
  }

  resetGrouping(groupingProgress?: boolean) {
    if (!groupingProgress) {
      this.isGroupingProgress = false;
      this.tempDropImages = [];
      this.groupedImageList = [];
      this.groupDocName = null;
      this.groupFileName = '';
      this.selectedGroup = null;
      this.dropTarget = '';
      this.fileExists = 0;
    }
    this.createFlattenFilesArray();
  }

  showImagePreview(i: number, fromGrouping: boolean) {
    const baseURL =
      this.baseUrl +
      'package/document/get-by-package/download/' +
      this.package.id +
      '/';
    if (fromGrouping) {
      this.previewImage = {
        index: i,
        url: baseURL + this.tempDropImages[i].image.id,
        fromGrouping: true,
      };
    } else {
      this.previewStack = this.package.anPackageDocumentDTOs.filter(
        (item) => !item.isClassified
      );
      const index = this.previewStack.findIndex(
        (item: any) => item.id == this.package.anPackageDocumentDTOs[i].id
      );
      this.previewImage = {
        index,
        url: baseURL + this.previewStack[index].id,
        fromGrouping: false,
      };
    }
    this.imagePreview = true;
  }

  closePreview() {
    this.imagePreview = false;
    this.currentRotateDegree = 0;
  }

  scrollHorizontally(i: number, direction: string) {
    const distance = direction === 'next' ? 200 : -200;
    this.filesList.get(i)?.nativeElement?.scrollBy(distance, 0);
  }

  onSaveClick(
    navigate: boolean = false,
    showToast: boolean = true,
    isNext?: boolean
  ) {
    if (this.sharedService.readOnlyMode()) {
      return;
    }
    this.package.percentageOfDocClassification = this.percentage.toString(); //update percentage

    const timer = this.sortHeadComponent.timer;
    this.package.timeForDocClassification =
      this.decimalPipe.transform(timer.hours, '2.0') +
      ':' +
      this.decimalPipe.transform(timer.minutes, '2.0') +
      ':' +
      this.decimalPipe.transform(timer.seconds, '2.0'); //update time

    if (this.unClassifiedImageCount) {
      this.package.isClassified = false;
    } else if (isNext) {
      this.package.isClassified = true; //update if classification completed
    }

    if (this.package.status === 'NEW' && !this.sharedService.readOnlyMode()) {
      this.package.status = 'WORK_IN_PROGRESS';
    }

    this.sharedService.showLoading();
    this.sharedService.selectedPackage.set(this.package);
    let packageTemp = JSON.parse(JSON.stringify(this.package));
    delete packageTemp.activityDTOs;
    delete packageTemp.anPackageDocumentDTOs;
    delete packageTemp.anPackageDocumentTypeDTOs;
    this.apiService.put('package', packageTemp).subscribe({
      next: (respose) => {
        this.packageDeepCopy = JSON.parse(JSON.stringify(this.package));
        this.createFlattenFilesArray();
        if (showToast) {
          this.sharedService.showToastMessage({
            message: 'Your edits have been saved successfully',
            type: MessageType.success,
          });
        }
        if (navigate) {
          this.preventSaveCall = true;
          this.route.navigate(['/packages', this.packageDBId, 'foi']);
        }
      },
      error: (err) => {
        this.restorePackage();
        this.sharedService.showToastMessage({
          message: 'Something went wrong',
          type: MessageType.error,
        });
        this.sharedService.hideLoading();
      },
      complete: () => {
        this.sharedService.hideLoading();
      },
    });
  }

  onNextClick() {
    if (
      JSON.stringify(this.packageDeepCopyTemp) == JSON.stringify(this.package)
    ) {
      this.package.packageEvent = '';
    } else {
      this.package.packageEvent = 'classifyCompleted';
    }
    this.package.page = '';
    this.onSaveClick(true, false, true);
  }

  getUnclassifiedImageCount() {
    if (this.package && this.package.anPackageDocumentDTOs) {
      this.unClassifiedImageCount = this.package.anPackageDocumentDTOs.filter(
        (item) => !item.isClassified
      ).length;
    }
  }

  calculatePercentage() {
    this.getUnclassifiedImageCount();
    const imgInProgress = this.totalImageCount - this.unClassifiedImageCount;
    if (imgInProgress > 0) {
      this.percentage = Math.round(
        (imgInProgress / this.totalImageCount) * 100
      );
    } else {
      this.percentage = 0;
    }
  }

  onStatusChange(status: string) {
    this.package.status = status;
  }

  ngOnDestroy(): void {
    if (!this.preventSaveCall) {
      this.package.page = '';
      this.onSaveClick(false, false);
    }
  }

  movePreview(next: boolean = true) {
    const baseURL =
      this.baseUrl +
      'package/document/get-by-package/download/' +
      this.package.id +
      '/';

    this.moveIndex = 0;
    this.moveIndex = next
      ? this.previewImage.index + 1
      : this.previewImage.index - 1;
    if (this.previewImage?.fromGrouping) {
      if (this.moveIndex > -1) {
        this.previewStack = this.tempDropImages;
        this.previewImage = {
          index: this.moveIndex,
          url: baseURL + this.tempDropImages[this.moveIndex].image.id,
          fromGrouping: true,
        };
      }
    } else {
      this.previewStack = Object.assign(
        this.package.anPackageDocumentDTOs.filter((item) => !item.isClassified)
      );
      if (this.moveIndex > -1) {
        this.previewImage = {
          index: this.moveIndex,
          url: baseURL + this.previewStack[this.moveIndex].id,
          fromGrouping: false,
        };
      }
    }
    this.imagePreview = true;
  }

  createFlattenFilesArray() {
    this.flattenedAnPackageDTOTypes = [];
    this.package.anPackageDocumentTypeDTOs?.forEach((e) => {
      if (e?.files?.length) {
        e.files.forEach((file) => {
          this.flattenedAnPackageDTOTypes.push({
            docType: e.id,
            name: file.name,
          });
        });
      }
    });
  }

  checkFileNameExists() {
    this.fileExists = 0;
    const matchingDTO = this.flattenedAnPackageDTOTypes.filter(
      (item: any) =>
        item.docType === this.groupDocName && item.name === this.groupFileName
    );
    return matchingDTO?.length || 0;
  }
  rotateImage() {
    this.currentRotateDegree += 90; 
    if (this.currentRotateDegree >= 360) {
        this.currentRotateDegree = 0;
    }
}
}
