import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DropdownSize, IDropdown } from '../../../_interfaces/packageList';
import { CamelCase1Pipe } from '../../../_pipes/camel-case1.pipe';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [CamelCase1Pipe, FormsModule, CommonModule],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  @Input() list: IDropdown[] = [];
  @Input() selected: IDropdown = { id: '', value: '' };
  @Input() placeHolder: string = '';
  @Input() isDisabled: boolean = false;
  @Input() hasFilter: boolean = false;
  @Input() converttoCamelCase: boolean = false;
  @Input() isShowDropdown: boolean = false;
  @Input() dropdownSize: DropdownSize = DropdownSize.Normal;
  @Input() isShowLoading: boolean = false;
  @Output() onSelect = new EventEmitter<IDropdown>();
  @Input() containerWidth: string = '100%';
  @Input() highlight: boolean = false;

  @ViewChild('dropdownContainer') dropdownContainerEl!: ElementRef<HTMLElement>;
  @ViewChild('dropdown')
  dropdownEl!: ElementRef<HTMLElement>;
  filter: string = '';
  backupList: IDropdown[] = [];
  private searchEl!: ElementRef<HTMLInputElement>;
  @ViewChild('search') set content(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.searchEl = content;
      this.searchEl.nativeElement.focus();
    }
  }
  constructor() {
    this.filter = '';
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['list'] && changes['list'].currentValue) {
      this.backupList = JSON.parse(
        JSON.stringify(changes['list'].currentValue)
      );
      // this.sliceList();
    }
    if (this.selected && this.selected.value == '') {
      //Reset
      //this.multiSelectedList = [];
      this.filter = '';
    }
  }
  sliceList() {
    if (this.list.length > 10 && this.hasFilter) {
      this.list = this.list.slice(0, 11);
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }
  @HostListener('document:click', ['$event'])
  clickOutsideComponent(event: Event) {
    if (event.target) {
      const target: Node = event.target as Node;
      if (this.dropdownContainerEl)
        if (!this.dropdownContainerEl.nativeElement.contains(target)) {
          this.isShowDropdown = false;
        }
    }
  }
  toggleDropdown(e: Event) {
    e.preventDefault();
    //e.stopPropagation();
    if (this.isDisabled) {
      this.isShowDropdown = false;
      return;
    }
    this.isShowDropdown = !this.isShowDropdown;
  }
  selectItem(e: Event, item: IDropdown) {
    e.preventDefault();
    e.stopPropagation();
    this.onSelect.emit(item);
    this.filter = '';
    this.list = this.backupList.filter((e) =>
      e.value.toLowerCase().startsWith(this.filter.toLowerCase())
    );
    this.list = JSON.parse(JSON.stringify(this.list));
    this.isShowDropdown = false;
  }

  onFilter = () => {
    this.list = this.backupList.filter((e) =>
      e.value.toLowerCase().startsWith(this.filter.toLowerCase())
    );
    this.list = JSON.parse(JSON.stringify(this.list));
    if (this.filter) {
      this.sliceList();
    }
  };
  getId(val: string): string {
    let id: string = '';
    if (val) {
      id = val.split(' ').join('-');
    }
    return id;
  }
  isShowOptionList(): boolean {
    let isShow: boolean = false;
    if (this.list && this.list.length) {
      const tempList = this.list.filter(
        (e) => e.value.toLowerCase().trim() != 'select all'
      );
      if (tempList && tempList.length) {
        isShow = true;
      }
    }
    return isShow;
  }
  selectedItem(event: MouseEvent, item: any) {
    // ... existing code ...

    if (item.value === 'Manage Filter') {
      this.onSelect.emit(item);
    }
  }
}
