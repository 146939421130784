<div class="pagination-wrapper" [ngClass]="{'d-none': !listCount}">
    <div>
        Showing {{pageSelected > 1 ? (pageSize * (pageSelected - 1)) + 1 : pageSelected}} to {{ listCount === pageSize ?
        pageSize * pageSelected: (pageSize * (pageSelected - 1)) + listCount}}
        of
        {{totalPackageLength}} records
    </div>

    <div class="pages-wrapper">
        <div class="page-navigate" (click)="loadPage(pageSelected - 1)">
            <svg class="action-icon" [ngClass]="{'disable':pageSelected - 1 < 1}">
                <use xlink:href="assets/icon-sprite.svg#arrow-prev" />
            </svg>
        </div>
        <div class="d-flex page-nav-list">
            <div class="page" [ngClass]="{'highlight': pageSelected == page.label}" *ngFor="let page of pageNavList"
                (click)="loadPage(page.value)">{{page.label}}</div>

        </div>
        <div class="page-navigate" (click)="loadPage(pageSelected + 1)">
            <svg class="action-icon" [ngClass]="{'disable':pageSelected + 1 > this.totalPages}">
                <use xlink:href="assets/icon-sprite.svg#arrow-next" />
            </svg>
        </div>
    </div>
</div>