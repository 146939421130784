export interface IDocFile {
  id: string;
  name: string;
  isExpand: boolean;
  images: string[];
}
export interface IDocType {
  id: string;
  name: string;
  // files: IDocFile[];
  files: any,
  isExpand: boolean;
}
export enum SortHeadType {
  Classification,
  FOI,
}

export interface IResponse {
  success: string;
  message: string;
  data: any;
}

export interface IDashboardResponse {
  sources: any;
  sessions_dict: any;
}

export interface IToast {
  type: MessageType;
  message: string;
  stayActive?: boolean;
}
export enum MessageType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export enum CursorMode {
  NONE,
  EXTRACT,
  ZOOM,
}