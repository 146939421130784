<div class="container">
  <div class="one">
    <div class="item" *ngIf="package.caseId">
      <div class="label">Case id</div>
      <div class="value">{{package.caseId}}</div>
    </div>
    <div class="border" *ngIf="package.packageId"></div>
    <div class="item" *ngIf="package.packageId">
      <div class="label">Package id</div>
      <div class="value package-id" [title]="package.packageId">{{package.packageId}}</div>
    </div>
    <div class="border" *ngIf="package.claimId"></div>
    <div class="item" *ngIf="package.claimId">
      <div class="label">Claim id</div>
      <div class="value">{{package.claimId}}</div>
    </div>
  </div>
  <div class="two">
    <div class="percentage-box d-flex" *ngIf="headType == SortHeadType.Classification" #percentageBox>
      <span>{{percentageVal}}%</span>
    </div>
    <div class="info">
      <div class="pages-left white-bg" *ngIf="headType == SortHeadType.Classification">{{imageLeftCount}} page(s) left
      </div>
      <div class="time-box white-bg">
        <div class="clock"></div>
        <div class="time">{{timer.hours|number : '2.0'}}:{{timer.minutes|number : '2.0' }}:{{timer.seconds|number :
          '2.0' }}</div>
      </div>
    </div>
  </div>
  <div class="three">
    <button class="btn btn-log" [disabled]="isGroupingProgress || !package" (click)="showActivityLog()"
      title="Activity log">
      <svg class="btn-icon">
        <use xlink:href="assets/icon-sprite.svg#info" />
      </svg>
    </button>
    <button class="btn btn-5" [disabled]="isGroupingProgress || !package" (click)="showBackButtonConfirmation()"
      title="Back">
      <svg class="btn-icon">
        <use xlink:href="assets/icon-sprite.svg#back_icon" />
      </svg>
    </button>
    @if(package.status != 'CANCELLED' && package.status != 'COMPLETED' && !sharedService.readOnlyMode()) {
    <button class="btn btn-4" [disabled]="isGroupingProgress || !package" title="Pause"
      *ngIf="package.status != 'ON_HOLD'">
      <svg class="btn-icon" (click)="showPauseConfirmation()">
        <use attr.xlink:href="assets/icon-sprite.svg#{{!pauseTimer? 'pause': 'play'}}" />
      </svg>
    </button>
    <button class="btn btn-save" [disabled]="isGroupingProgress || !package" (click)="onSaveClick()" title="Save">
      <svg class="btn-icon">
        <use xlink:href="assets/icon-sprite.svg#save" />
      </svg>
    </button>
    <!-- @if(package.status != 'COMPLETED') { -->
    <button class="btn btn-2" [disabled]="isGroupingProgress || !package" (click)="cancelProcess()" title="Cancel">
      <svg class="btn-icon">
        <use xlink:href="assets/icon-sprite.svg#cancel" />
      </svg>
    </button>
    <!-- } -->
    <!-- title="Submit package to courtex" -->
    <button class="btn btn-8" *ngIf="headType == SortHeadType.FOI"
    [disabled]="isGroupingProgress || !package" 
      (click)="showSubmitButtonConfirmation()">
      <svg class="btn-icon">
        <use xlink:href="assets/icon-sprite.svg#tick-circle" />
      </svg>
    </button>
    }
    @if(package.status != 'CANCELLED' && !sharedService.readOnlyMode()) {
    <button class="btn btn-1" *ngIf="headType == SortHeadType.Classification"
      [disabled]="isGroupingProgress || imageLeftCount || !package" (click)="nextButtonConfirmation.isShow = true"
      [title]="imageLeftCount? 'The Next button becomes accessible only once document classification is completed on this page.' : 'Next'">
      <svg class="btn-icon">
        <use xlink:href="assets/icon-sprite.svg#next" />
      </svg>
    </button>
    }
  </div>
</div>

<div #cancelPopup class="overlay" (click)="closeCancel()">
  <div class="popup" (click)="$event.stopPropagation()">
    <div>Cancel</div>
    <div class="close" (click)="closeCancel()">&times;</div>
    <div class="content">
      <select class="select input" [(ngModel)]="cancelReason.reason">
        <option value="" disabled selected hidden>Reasons</option>
        @for (reason of sharedService.cancelReasons(); track reason) {
        <option [value]="reason.value">{{reason.value}}</option>
        }
      </select>
      <label class="error" *ngIf="!cancelReason.reason && isSubmitted">Please choose a reason</label>
      <textarea [(ngModel)]="cancelReason.comment" placeholder="Enter your comments here" class="input" rows="6"
        maxlength="200"></textarea>
      <div class="warning d-flex">
        <svg>
          <use xlink:href="assets/icon-sprite.svg#warning" />
        </svg>
        Would you like to cancel the package?
      </div>

      <div class="btn-wrapper d-flex">
        <button class="btn btn-secondary" (click)="closeCancel()">No</button>
        <button class="btn btn-primary" (click)="onCancelYes()">Yes</button>
      </div>
    </div>
  </div>
</div>

<app-activity-popup (onClose)="isShowActivityLog = $event" [packageId]="package.id" [isShowPopup]="isShowActivityLog">
</app-activity-popup>

<app-confirm-popup *ngIf="backButtonConfirmation.isShow" [heading]="backButtonConfirmation.title"
  [message]="backButtonConfirmation.message" (yes)="navigateListing(); backButtonConfirmation.isShow = false"
  (no)=" backButtonConfirmation.isShow = false"></app-confirm-popup>
<app-confirm-popup *ngIf="pauseButtonConfirmation.isShow" [heading]="pauseButtonConfirmation.title"
  [message]="pauseButtonConfirmation.message"
  (yes)="!isGroupingProgress? pauseTimer = !pauseTimer: ''; navigatePause(); pauseButtonConfirmation.isShow = false"
  (no)=" pauseButtonConfirmation.isShow = false"></app-confirm-popup>
<app-confirm-popup *ngIf="nextButtonConfirmation.isShow" [heading]="nextButtonConfirmation.title"
  [message]="nextButtonConfirmation.message" (yes)="onNextClick(); nextButtonConfirmation.isShow = false"
  (no)=" nextButtonConfirmation.isShow = false"></app-confirm-popup>
  <app-confirm-popup *ngIf="submitButtonConfirmation.isShow" [heading]="submitButtonConfirmation.title"
    [message]="submitButtonConfirmation.message" (yes)="submit(); submitButtonConfirmation.isShow = false"
    (no)=" submitButtonConfirmation.isShow = false"></app-confirm-popup>