<div class="cp-overlay" (click)="noClicked()">
    <div class="cp-container" (click)="$event.stopPropagation()">
        <div class="cp-header d-flex">
            <svg class="icon25">
                <use xlink:href="assets/icon-sprite.svg#confirm" />
            </svg>
            {{heading}}
        </div>
        <div class="cp-body">
            <div class="cp-message">
                {{message}}
            </div>
        </div>
        <div class="cp-footer">
            <button class="btn btn-secondary" (click)="noClicked()">No</button>
            <button class="btn btn-primary" (click)="yesClicked()">Yes</button>
        </div>
    </div>
</div>