import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedService } from '../../../_services/shared.service';

@Component({
  selector: 'app-dashboard-filter',
  standalone: true,
  imports: [FormsModule, CommonModule],
  providers: [DatePipe],
  templateUrl: './dashboard-filter.component.html',
  styleUrl: './dashboard-filter.component.scss'
})
export class DashboardFilterComponent {
  @Input() title: string = '';
  @Input() filterList: string[] = ['Case Name'];
  @Output() emitApplyFilter = new EventEmitter<any>();
  @Output() emitResetFilter = new EventEmitter<any>();

  dateFrom!: string | null;
  dateTo!: string | null;
  caseName: string = '';
  caseList: any = [];
  sessionStored: boolean = false;
  maxDate!: string | null;
  maxFromDate!: string | null;
  datePipe = inject(DatePipe);
  valueSelected: any = '';

  sharedService = inject(SharedService);

  uniqueList: any = {};
  filterApplied: any = {};

  constructor() {
    this.resetDate();
  }

  ngOnInit() {
    const sources = localStorage.getItem('sources');
    if (sources) {
      const uniqueCols = JSON.parse(sources).angeionData.unique_columns;
      if (uniqueCols) {
        this.filterList.forEach(item => {
          this.uniqueList[item] = uniqueCols[item][item];
        })
      }
    }
  }

  inputChange(list: any) {
    this.filterApplied[list] = this.valueSelected;
  }

  applyFilter() {
    const filterApplied = Object.assign({}, this.filterApplied, { dateFrom: this.dateFrom, dateTo: this.dateTo });
    console.log(filterApplied);
    this.emitApplyFilter.emit(filterApplied);
  }

  resetFilter() {
    this.resetDate();
    this.caseName = '';
    this.valueSelected = '';
    this.emitResetFilter.emit();
  }

  resetDate() {
    this.maxFromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.dateTo = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    let dt = new Date();
    dt.setMonth(dt.getMonth() - 3)

    this.dateFrom = this.datePipe.transform(dt, 'yyyy-MM-dd');
    this.maxDate = this.dateTo;
  }

  onChangeFromDate(event: any) {
    if (this.dateFrom) {
      let dt = new Date(this.dateFrom);
      dt.setMonth(dt.getMonth() + 3)
      if(dt > new Date()) {
        dt = new Date();
      }
      this.dateTo = this.datePipe.transform(dt, 'yyyy-MM-dd');
      this.maxDate = this.dateTo;
    }
  }


}
