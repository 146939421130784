import { CommonModule, DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { MessageType } from '../../../../_interfaces/entity';
import { ApiService } from '../../../../_services/api.service';
import { SharedService } from '../../../../_services/shared.service';
import { PaginationComponent } from '../../../_common/pagination/pagination.component';

@Component({
  selector: 'app-productivity-report',
  standalone: true,
  imports: [CommonModule, FormsModule, PaginationComponent],
  providers: [DatePipe],
  templateUrl: './productivity-report.component.html',
  styleUrl: './productivity-report.component.scss',
})
export class ProductivityReportComponent {
  @Input() coderNameList: string[] = [];
  monthsList: any[] = [];
  selectedDate: string = '';
  selectedMonth: any = '';
  tempSelectedDate: string = '';
  tempSelectedMonth: any = '';
  tempSelectedUser: string = '';

  isMonthly: boolean = true;
  selectedUser: string = '';
  apiService = inject(ApiService);
  sharedService = inject(SharedService);
  reportData: any[] = [];
  datePipe = inject(DatePipe);
  totalRecords: number = 0;
  pageNumber: any = 0;
  pageSize: number = 20;
  currentdate: Date = new Date();
  maxDate: String = this.currentdate.toISOString().split('T')[0];

  ngOnInit() {
    this.populateMonthsList();
    this.angeionDataStorage();
  }

  angeionDataStorage() {
    const sources = localStorage.getItem('sources');
    if (sources) {
      const uniqueCols = JSON.parse(sources).angeionData_archive.unique_columns;
      if (uniqueCols) {
        this.coderNameList = uniqueCols['User Name'];
      }
    }

    // if(!sources || sources && !JSON.parse(sources).angeionData) {
    //   this.loadAllData();
    //}
  }

  loadAllData() {
    this.sharedService.dashboardSessionStored.set(false);
    this.sharedService.showLoading();
    const data = {
      sourceSet: ['angeionData'],
      client_id: 'Angieon_uuid',
    };
    const baseUrl = environment.baseUrlDashboard;
    this.apiService
      .dashboardPost(baseUrl + '/load_multiple_data', data)
      .subscribe({
        next: (data) => {
          if (data) {
            const uniqueCols = data.sources?.angeionData?.unique_columns;
            if (uniqueCols) {
              this.sharedService.dashboardCaseList.set(
                uniqueCols['Case Name']['Case Name']
              );
              this.coderNameList = uniqueCols['User Name'];
            }
            if (data.sessions_dict) {
              sessionStorage.setItem(
                'session_dict',
                JSON.stringify(data.sessions_dict)
              );
              localStorage.setItem('sources', JSON.stringify(data.sources));
              //this.sharedService.isSessionStored.set(true);
            }
            this.sharedService.dashboardSessionStored.set(true);
          }
          this.sharedService.hideLoading();
        },
        error: (err) => {
          console.error('Error fetching data', err);
          this.sharedService.hideLoading();
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }

  populateMonthsList() {
    const currentDate = new Date();
    for (let i = 0; i < 3; i++) {
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i + 1,
        0
      );
      this.monthsList.push({
        startDate: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
        month: this.datePipe.transform(startDate, 'MMMM yyyy'),
      });
    }

    console.log(this.monthsList);
  }

  applyProdReportFilter() {
    if (!this.tempSelectedMonth && !this.tempSelectedDate) {
      return;
    }
    this.selectedMonth = this.tempSelectedMonth;
    this.selectedDate = this.tempSelectedDate;
    this.sharedService.showLoading();
    const queryParams = `coderName=${this.selectedUser}&startDate=${
      this.isMonthly ? this.selectedMonth.startDate : this.selectedDate
    }&endDate=${
      this.isMonthly ? this.selectedMonth.endDate : this.selectedDate
    }&pageSize=${this.pageSize}&startIndex=${this.pageNumber}`;
    this.apiService.get('package/report/search?' + queryParams).subscribe({
      next: (response) => {
        if (response?.success == 'true' && response?.data) {
          this.reportData = response.data.data;
          this.totalRecords = response.data.totalRecords;
        }
      },
      error: (err) => {
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
        this.sharedService.hideLoading();
      },
      complete: () => {
        this.sharedService.hideLoading();
      },
    });
  }

  resetProdReportFilter() {}

  paginationNavigation(pageno: any) {
    this.pageNumber = pageno - 1;
    this.applyProdReportFilter();
  }

  downloadFile(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/octet-stream',
      }),
      responseType: 'blob' as 'json', // Set responseType to 'blob' to handle binary data
    };

    // Adjust the API endpoint as needed
    return this.apiService.post(
      'https://so-api.staging.com/api/package/report/export',
      options
    );
  }

  exportReport() {
    this.sharedService.showLoading();
    const queryParams = `coderName=${this.selectedUser}&startDate=${
      this.isMonthly ? this.selectedMonth.startDate : this.selectedDate
    }&endDate=${
      this.isMonthly ? this.selectedMonth.endDate : this.selectedDate
    }`;
    this.apiService
      .exportPost('package/report/export?' + queryParams)
      .subscribe({
        next: (response) => {
          if (response) {
            this.saveFile(response, 'productivity-report.csv');
          }
        },
        error: (err) => {
          this.sharedService.showToastMessage({
            message: err.message || 'Error',
            type: MessageType.error,
          });
          this.sharedService.hideLoading();
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }

  saveFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  validateDate() {
    const today = new Date().toISOString().split('T')[0];
    if (this.selectedDate > today) {
      this.sharedService.showToastMessage({
        message: 'Future dates are not allowed!',
        type: MessageType.error,
      });
      this.selectedDate = '';
    }
  }
}
