<div class="container">
    <div class="head">
        <app-sort-head [headType]="SortHeadType.Classification" [percentage]="percentage" [timerSaved]="timerSaved"
            [imageLeftCount]="unClassifiedImageCount" [isGroupingProgress]="isGroupingProgress" [package]="package"
            (onSave)="this.package.page = 'DC'; onSaveClick()" (onNext)="onNextClick()"
            (emitSaveCall)="onStatusChange($event)">
        </app-sort-head>
    </div>
    <div class="body">
        <div class="left" (drop)="dropImagesBackToLeft($event)" (dragover)="allowDrop($event)">
            <div class="page-title">
                <div class="title"><span class="underline">Document</span> Classification</div>
            </div>
            @if(unClassifiedImageCount) {
            <div class="image-list-box">
                @for (image of package.anPackageDocumentDTOs; track image.id; let i=$index) {
                <div #imageList class="image-item" [attr.id]="i" *ngIf="!image.isClassified"
                    [ngClass]="{'highlight': checkIfImageSelected(i, image.id)>-1}"
                    (click)="selectImage(i, $event, image.id);">
                    <img [attr.id]="'file_' + i"
                        [src]="baseUrl+'package/document/get-by-package/download/'+package.id+'/'+image.id" alt=""
                        [draggable]="!sharedService.readOnlyMode()" (dragstart)="dragFileToRight($event)" (dragend)="dropTarget=''"
                        (contextmenu)="$event.preventDefault();">
                    <div class="image-options">
                        <input *ngIf="package.status != 'CANCELLED' && package.status != 'COMPLETED'" type="checkbox" [attr.id]="'check_' + i"
                            [checked]="checkIfImageSelected(i, image.id)>-1"
                            (click)="selectImage(i, $event, image.id);">
                        <label [for]="'check_'+i"></label>
                        <svg class="zoom-icon" (click)="showImagePreview(i, false); $event.stopPropagation();">
                            <use xlink:href="assets/icon-sprite.svg#search-plus" />
                        </svg>
                    </div>
                </div>
                }
            </div>
            }
            @else {
            <p class="work-done">You're all caught up for now</p>
            }
        </div>
        <div class="right">
            <div class="group-button">
                <button class="btn btn-group" (click)="groupImages()"
                    *ngIf="unClassifiedImageCount && this.package.status != 'CANCELLED' && package.status != 'COMPLETED'"
                    [disabled]="isGroupingProgress || !unClassifiedImageCount || sharedService.readOnlyMode()">Group Pages
                    <svg class="btn-icon">
                        <use xlink:href="assets/icon-sprite.svg#play" />
                    </svg>
                </button>
            </div>
            <div class="doc-type-box" [ngClass]="{'is-empty': !package || !package.anPackageDocumentTypeDTOs.length}">
                <p *ngIf="!package || !package?.anPackageDocumentTypeDTOs?.length">No Document types</p>
                @for (doc of package.anPackageDocumentTypeDTOs; track doc.id; let i=$index;) {
                <div class="doc-main-box">
                    <div class="doc-name">
                        {{doc.name}} <span class="doc-count">({{docCount(i)}})</span>
                    </div>
                    <div class="doc-box">
                        <div class="prev" (click)="scrollHorizontally(i, 'prev')">
                            <svg>
                                <use xlink:href="assets/icon-sprite.svg#arrow-prev-double" />
                            </svg>
                        </div>
                        <div [attr.id]="'box_' + doc.id" class="doc-main" (drop)="drop($event, doc.id)"
                            (dragover)="allowDrop($event); dropTarget=doc.id" [class.highlight]="dropTarget == doc.id">
                            <div *ngIf="!doc.files?.length" class="drag-box">
                                <p class="place-holder">Drag and drop files here</p>
                            </div>
                            <div class="files-list" #filesList [ngClass]="{'d-none': !doc?.files?.length}">
                                @for (group of doc.files ; track group; let j = $index) {
                                <div class="stacked-images" [id]="'stackedimg_'+j"
                                    (click)="showGrouping(group, i, doc.id, j)" [draggable]="!sharedService.readOnlyMode()"
                                    (dragstart)="dragGroup($event, group, i, doc.id, j)"
                                    (drop)="shuffleInDoctype($event, doc.id, j)">
                                    <div class="image-wrap" #imageWrap>
                                        @for (image of group.images; track image; let k=$index) {
                                        @if(k < 4) { <img class="img"
                                            [src]="baseUrl+'package/document/get-by-package/download/'+package.id+'/'+image"
                                            (contextmenu)="$event.preventDefault();">
                                            }
                                            }
                                            <span class="close-icon close" *ngIf="package.status!='CANCELLED' && package.status != 'COMPLETED' && !sharedService.readOnlyMode()"
                                                (click)="showdeleteGroupConfirmation(group, i, j, $event)">&times;</span>
                                    </div>
                                    <div class="group-name"
                                        [ngStyle]="{'width.px': ((group.images.length> 4?  280 : group.images.length * 70) - (group.images.length> 4? 150 : (group.images.length - 1) * 50))}"
                                        [title]="group.name">
                                        {{group.name}}</div>

                                </div>

                                }
                            </div>
                        </div>
                        <div class="next" (click)="scrollHorizontally(i, 'next')">
                            <svg>
                                <use xlink:href="assets/icon-sprite.svg#arrow-next-double" />
                            </svg>
                        </div>
                    </div>
                </div>
                }
            </div>

            <div class="group-popover" *ngIf="isGroupingProgress" (drop)="drop($event, groupDocName)"
                (dragover)="allowDrop($event)">
                <div class="d-flex header-wrapper">
                    <div>Group pages</div>
                    <div class="btn-wrapper">
                        <button class="btn btn-6" (click)="isGroupingProgress=false; cancelGrouping()">Cancel</button>
                        <button class="btn btn-3" (click)="saveGroup()"
                            [disabled]="!selectedGroup && (!tempDropImages || !tempDropImages.length) || !groupFileName || !groupDocName || fileExists || package.status == 'CANCELLED' || package.status== 'COMPLETED'">Save</button>
                    </div>
                </div>
                <div class="fields inputs">
                    <div>
                        <label for="doc_type">Doc type</label>
                        <select class="select input" id="doc_type" [(ngModel)]="groupDocName"
                            (change)="onChangeDocType()">
                            @for (doc of package.anPackageDocumentTypeDTOs; track doc.id) {
                            <option [value]="doc.id">{{doc.name}}</option>
                            }
                        </select>
                    </div>
                    <div>
                        <label for="file_name">File name</label>
                        <input class="input" id="file_name" [(ngModel)]="groupFileName"
                            (change)="fileExists = checkFileNameExists()">
                        <div class="error" *ngIf="!groupFileName">Please provide file name</div>
                        <div class="error"
                            *ngIf="groupFileName && ((selectedGroup && fileExists > 1) || (!selectedGroup && fileExists))">
                            File name already exists!
                            Please choose a different one.</div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="images-list">
                        @for (tempImg of tempDropImages; track tempImg.index; let i=$index) {
                        <div class="image-wrapper" [id]="'tempimg_' + tempImg?.image?.id" [draggable]="!sharedService.readOnlyMode()"
                            (dragstart)="dragWithinPopover($event, i)" (drop)="shuffleInPopover($event, i)">
                            <figure class="hover-img">
                                <img [src]="baseUrl+'package/document/get-by-package/download/'+package.id+'/'+tempImg.image.id"
                                    draggable=false (contextmenu)="$event.preventDefault();">
                                <span class="close-icon close" *ngIf="package.status!='CANCELLED' && package.status != 'COMPLETED'"
                                    (click)="showDeletePageConfirmation(tempImg, i); $event.stopPropagation();">&times;</span>
                                <figcaption>
                                    <svg class="zoom-icon" (click)="showImagePreview(i, true)">
                                        <use xlink:href="assets/icon-sprite.svg#search-plus" />
                                    </svg>

                                </figcaption>
                            </figure>
                            <div>{{i + 1}}</div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-confirm-popup *ngIf="deleteGroupConfirmation && deleteGroupConfirmation.group" [heading]="'Confirmation'"
    [message]="'Do you wish to remove the grouped file ?'" (yes)="deleteGroup();"
    (no)="resetDeleteGroupConfirmation();"></app-confirm-popup>

<app-confirm-popup *ngIf="deletePageConfirmation && deletePageConfirmation.i > -1" [heading]="'Confirmation'"
    [message]="'Do you wish to remove the page ?'" (yes)="removeImage();" (no)="resetDeletePageConfirmation();">
</app-confirm-popup>


@if(imagePreview) {
<div class="overlay preview" (click)="closePreview()">
    <div class="prev" (click)="$event.stopPropagation();movePreview(false)">
        <svg>
            <use xlink:href="assets/icon-sprite.svg#arrow-prev-double" />
        </svg>
    </div>
    <div class="popup" (click)="$event.stopPropagation()" (contextmenu)="$event.preventDefault();">
        <div class="close" (click)="closePreview()">&times;</div>
        <svg class="icon rotate-icon" (click)="rotateImage()">
            <use xlink:href="assets/icon-sprite.svg#rotate" />
        </svg>
        <img [src]="previewImage?.url" [style.transform]="'rotate(' + currentRotateDegree + 'deg)'" (contextmenu)="$event.preventDefault();" />
    </div>
    <div class="next" (click)="$event.stopPropagation();movePreview(true)">
        <svg>
            <use xlink:href="assets/icon-sprite.svg#arrow-next-double" />
        </svg>
    </div>
</div>
}