import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  inject,
  OnDestroy,
  Input,
} from '@angular/core';
import { SortHeadComponent } from '../_common/sort-head/sort-head.component';
import {
  CursorMode,
  MessageType,
  SortHeadType,
} from '../../../_interfaces/entity';
import { CommonModule, DecimalPipe } from '@angular/common';
import {
  Formio,
  FormioComponent,
  FormioModule,
  FormioSubmissionCallback,
} from '@formio/angular';
import { ImageComponent } from './image/image.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../../_services/shared.service';
import { ApiService } from '../../../_services/api.service';
import { IPackage } from '../../../_interfaces/packageList';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';

enum FormIOStage {
  STAGE1,
  STAGE2,
}
enum FormIOAPICallStage {
  INITIAL,
  STARTED,
  COMPLETED,
  FAILED,
}

@Component({
  selector: 'app-foi',
  standalone: true,
  imports: [SortHeadComponent, CommonModule, FormioModule, ImageComponent],
  providers: [DecimalPipe],
  templateUrl: './foi.component.html',
  styleUrl: './foi.component.scss',
})
export class FoiComponent implements OnInit, OnDestroy {
  baseUrl: string = environment.baseUrl;
  formioLookupForm: string = `${environment.formio.baseUrl}${environment.formio.lookupFormName}`;
  formioSubmissionForm: string = '';
  formSubmissionData: any = {};
  formDefinition: any = {};
  formLookupData: Object = {};
  formIOStage: FormIOStage = FormIOStage.STAGE1;
  formIOAPICallStage: FormIOAPICallStage = FormIOAPICallStage.INITIAL;
  disableButtons: boolean = false;
  // doctypes: IDocType[] = [];
  previewImage: any = { docIndex: '', fileIndex: '', url: '' };
  selectedElement!: HTMLInputElement;
  rotations: number[] = [];
  currentRotateState: number = 0;
  zoomLevel: number = 1;
  @ViewChild('previewBox', { static: true }) previewBoxEl!: ElementRef;
  previewWidth: number = 100;
  @ViewChild('formiobox', { static: true }) formioboxEl!: ElementRef;
  packageDBId: string = '';
  percentage: number = 0;
  package: IPackage;
  isShowCopiedNotification: boolean = false;
  activatedRoute = inject(ActivatedRoute);
  router = inject(Router);
  sharedService = inject(SharedService);
  apiService = inject(ApiService);
  selectedPage: number = 1;
  flattenArray: any = [];
  selectedDoc: string = '';
  decimalPipe = inject(DecimalPipe);
  isSubmitButtonEnabled: boolean = false;
  preventSaveCallOnDestroy: boolean = false;
  concurrencySubscription: Subscription = new Subscription();
  logoutSubscription: Subscription = new Subscription();
  submissionFormTitle: string = '';
  formDefinistionSubmissionData: Object = {};
  submissionFormioObject!: FormioComponent;
  cursorMode: CursorMode = CursorMode.ZOOM;
  isArchived: boolean = false;
  @Input() isCourtexView: boolean = false;

  @ViewChild(SortHeadComponent, { static: true })
  sortHeadComponent!: SortHeadComponent;

  constructor() {
    this.package = {
      activityDTOs: [],
      anPackageDocumentDTOs: [],
      anPackageDocumentTypeDTOs: [],
      caseId: '',
      caseName: '',
      claimId: '',
      coderId: '',
      coderName: '',
      createdBy: '',
      createdDateTime: '',
      id: '',
      lastUpdateDate: '',
      lastUpdatedBy: '',
      packageId: '',
      percentageDocExtraction: '',
      percentageOfDocClassification: '',
      sourceSystem: '',
      status: '',
      submissionId: '',
      timeForDocClassification: '',
      timeForDocExtraction: '',
      updatedBy: '',
      updatedDateTime: '',
      isClassified: false,
      formioId: '',
      formioName: '',
      page: '',
      packageEvent: '',
    };
    this.concurrencySubscription = this.sharedService.showConcurrency.subscribe(
      (val) => {
        if (val) {
          this.preventSaveCallOnDestroy = true;
        }
      }
    );

    this.logoutSubscription = this.sharedService.logout.subscribe((val) => {
      if (val) {
        if (this.router?.url.includes('foi')) {
          console.log(
            'foi logout',
            this.activatedRoute.snapshot.routeConfig?.path
          );
          this.package.page = '';
          this.onSaveClick(true, false, true);
          this.preventSaveCallOnDestroy = true;
          this.sharedService.logout.next(false);
        }
      }
    });
  }
  // beforeSubmitHandler!: (submission: object, callback: FormioSubmissionCallback) => void
  // submissionFormOptions = {
  //   hooks: { beforeSubmit: this.beforeSubmitSubmissionForm.bind(this) },
  // };
  ngOnInit(): void {
    if(this.router.url && this.router.url.includes("archives")) {
     this.isArchived = true; 
    }
    // this.beforeSubmitHandler = this.beforeSubmitSubmissionForm.bind(this);

    if (this.activatedRoute.snapshot.paramMap.get('readonly') == 'readonly') {
      this.sharedService.readOnlyMode.set(true);
      this.sharedService.showToastMessage({
        message: 'You are now in Read Only mode.',
        type: MessageType.info,
      });
    } else {
      if (this.isCourtexView) {
        this.sharedService.readOnlyMode.set(true);
      } else {
        this.sharedService.readOnlyMode.set(false);
      }
    }
    if (this.isCourtexView) {
      this.packageDBId =
        this.activatedRoute.snapshot.paramMap.get('packageId') || '';
    } else {
      this.packageDBId =
        this.activatedRoute.parent?.snapshot.paramMap.get('packageId') || '';
    }

    // this.previewImage =
    //   this.package.anPackageDocumentTypeDTOs[0].files[0].images[0];
    this.loadPackage();

    this.previewWidth = (<HTMLElement>(
      this.previewBoxEl.nativeElement
    )).offsetWidth;
    this.initializeRotations();
  }
  ngOnDestroy(): void {
    if (!this.preventSaveCallOnDestroy) {
      this.package.page = '';
      this.onSaveClick(false, false, true);
    }
  }

  loadPackage() {
    let pageCount = 0;
    this.sharedService.showLoading();
    let packageBaseUrl = 'package';
    if(this.isArchived) {
      packageBaseUrl = 'package/archived'
    }
    let getPackageUrl = `${packageBaseUrl}/document/get-by-package/${this.packageDBId}`;
    if (this.isCourtexView) {
      //TODO: update this URL with new

      console.log(atob(this.packageDBId));

      getPackageUrl = `${packageBaseUrl}/get-by-package/${atob(this.packageDBId)}`;
    }
    this.apiService.get(getPackageUrl).subscribe({
      next: (response) => {
        this.package = response.data;
        if (this.package.status) {
          this.package.status = this.package.status.toUpperCase();
        }
        if (this.package.status == 'ON_HOLD') {
          this.package.status = 'WORK_IN_PROGRESS';
          this.package.page = '';
          this.onSaveClick(true, false, true);
        }

        this.percentage = this.package.percentageDocExtraction
          ? parseInt(this.package.percentageDocExtraction)
          : 0;

        this.sharedService.selectedPackage.set(this.package);
        if (this.package.anPackageDocumentTypeDTOs) {
          this.package.anPackageDocumentTypeDTOs?.forEach((e, docIndex) => {
            if (e?.files?.length) {
              e.files.forEach((file, fileIndex) => {
                if (file?.images?.length) {
                  file.images.forEach((image, pageIndex) => {
                    pageCount++;
                    this.flattenArray.push({
                      docIndex,
                      fileIndex,
                      pageIndex,
                      image,
                      pageCount,
                    });
                  });
                  if (!this.previewImage?.url) {
                    e.isExpand = true;
                    file.isExpand = true;
                    let packageBaseUrl = 'package';
                    if(this.isArchived) {
                      packageBaseUrl = 'package/archived'
                    }
                    this.previewImage = {
                      docIndex,
                      fileIndex,
                      pageIndex: 0,
                      url: `${this.baseUrl}${packageBaseUrl}/document/get-by-package/download/${this.package.id}/${file.images[0]}`,
                    };
                    this.selectedDoc =
                      this.package.anPackageDocumentTypeDTOs[docIndex].name;
                  }
                }
              });
            }
          });
        }
        this.formLookupData = {
          data: {
            zero_zero_id: this.package.claimId,
            case_id: this.package.caseId,
          },
        };
        if (this.package.claimId && this.package.claimId != 'unknown') {
          this.retriveCaseFormId({});
        }
      },
      error: (err) => {
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
        this.sharedService.hideLoading();
      },
      complete: () => {
        this.sharedService.hideLoading();
      },
    });
  }

  get SortHeadType() {
    return SortHeadType;
  }

  setEventListerner(form: FormioComponent) {
    console.log({ form });
    if (form) {
      this.submissionFormioObject = form;
      if (
        this.submissionFormioObject?.submission?.data?.metadata?.zero_zero_id
      ) {
        this.submissionFormioObject.submission.data.metadata.zero_zero_id =
          this.package.claimId;
      }
    }
    if (form?.submission?.data) {
      this.formSubmissionData = form.submission;
    }

    if (form?.label) {
      this.submissionFormTitle = form.label;
    }
    form.options = {
      hooks: {
        beforeSubmit: this.handleBeforeSubmit.bind(this),
      },
    };

    //this.addEventsForSelecttedEelemts();
  }

  /* addEventsForSelecttedEelemts() {
     setTimeout(() => {
      const formIOEl: HTMLElement = this.formioboxEl
        .nativeElement as HTMLElement;
      if (formIOEl) {
        formIOEl
          .querySelectorAll('.formio-component-container')
          .forEach((el) => {
            el.querySelectorAll('input').forEach((element) => {
              if (element) {
                (<HTMLInputElement>element).addEventListener(
                  'click',
                  (event) => {
                    if (event?.target instanceof HTMLInputElement) {
                      console.log(event.target, 'event.target');
                      this.selectedElement = event.target as HTMLInputElement;
                      console.log(this.selectedElement, 'this.selectedElement');
                      this.setSelectedElement(this.selectedElement);
                    }
                  }
                );
              }
            });
            el.querySelectorAll('textarea').forEach((element) => {
              if (element) {
                element.addEventListener('click', (event) => {
                  if (event?.target instanceof HTMLInputElement) {
                    this.selectedElement = event.target as HTMLInputElement;
                    this.setSelectedElement(this.selectedElement);
                  }
                });
              }
            });
          });
          el.querySelectorAll('textarea').forEach((element) => {
            if (element) {
              element.addEventListener('click', (event) => {
                if (event?.target instanceof HTMLInputElement) {
                  this.selectedElement = event.target as HTMLInputElement;
                  this.setSelectedElement(this.selectedElement);
                }
              });
            }
          });
        });
      }
    }, 1000); 
  } */

  setSelectedElement(selectElement: HTMLInputElement) {
    const formIOEl: HTMLElement = this.formioboxEl.nativeElement as HTMLElement;
    if (formIOEl) {
      formIOEl.querySelectorAll('input').forEach((element) => {
        if (element) {
          element.style.border = '';
        }
      });
      formIOEl.querySelectorAll('textarea').forEach((element) => {
        if (element) {
          element.style.border = '';
        }
      });
    }
    if (selectElement) {
      if (selectElement) {
        selectElement.style.border =
          '1px solid var(--foi-doctype-tree-bg-color)';
      }
    }
  }

  calculatePercentage(submissionData: any) {
    console.log('calculatePercentage');

    if (submissionData) {
      Object.keys(submissionData).forEach((key) => {
        // console.log({ key });
        // console.log(submissionData[key]);
      });
    }
  }
  onTextSelect(text: string) {
    if (text) {
      if (this.selectedElement && !this.selectedElement.value) {
        this.selectedElement.value = text;
        const name = this.selectedElement.getAttribute('name');
        const properties = (<string>name).split(/\[|\]/).filter(Boolean);

        let target = this.formSubmissionData;
        console.log({ target });

        for (let i = 0; i < properties.length - 1; i++) {
          if (target[properties[i]]) {
            target = target[properties[i]];
          }
        }

        // Set the value to the target property
        target[properties[properties.length - 1]] = text;
        // this.formSubmissionData = JSON.parse(JSON.stringify(target));
        this.calculatePercentage(target);
      } else {
        navigator.clipboard.writeText(text);
        this.showCopiedNotification();
      }
    }
    // const formIOEl: HTMLElement = this.formioboxEl.nativeElement as HTMLElement;
    // if (formIOEl) {
    //   console.log(formIOEl.querySelectorAll('.formio-form-group'));
    //   formIOEl.querySelectorAll('.formio-form-group').forEach((el) => {
    //     console.log({ el });
    //   });
    //   console.log(document.activeElement);
    // }
  }
  initializeRotations() {
    this.rotations = [0, Math.PI / 2, Math.PI, (3 * Math.PI) / 2];
  }
  rotateProcess() {
    this.currentRotateState++;
    if (this.currentRotateState >= this.rotations.length) {
      this.currentRotateState = 0;
    }
  }

  zoomIn() {
    this.zoomLevel *= 1.2; // Adjust the zoom factor as needed
  }

  zoomOut() {
    this.zoomLevel /= 1.2; // Adjust the zoom factor as needed
  }
  onFinalFormSubmit(data: any) {
    this.isSubmitButtonEnabled = true;
  }

  retriveCaseFormId(formioData: any) {
    console.log({ formioData });
    //Assigning claim id
    //TODO: there may be a change in field formioData?.data?.notice_id
    //ABC00001
    if (formioData?.data?.zero_zero_id) {
      this.package.claimId = formioData?.data?.zero_zero_id;
      this.package.page = '';
      this.onSaveClick(true, false, true);
    }

    this.sharedService.showLoading();
    //TODO: remove caseId hard coding.
    // this.package.caseId = '1';
    this.apiService
      .post('api/integration/courtex/generic-api', {
        path: `/api/v1/cases/${this.package.caseId}/form`,
        method: 'GET',
        input: {},
      })
      .subscribe({
        next: (response) => {
          console.log({ response });
          if (response?.success == 'true') {
            if (typeof response.data === 'object') {
              this.package.formioId = response?.data?.data?.formio_id;
              this.package.formioName = response?.data?.data?.form_name;
              console.log(this.package);

              this.retriveClaimSubmissionId();
            } else {
              this.sharedService.showToastMessage({
                message: response.data,
                type: MessageType.info,
              });
            }
          }
        },
        error: (err) => {
          console.log({ err });
          this.sharedService.hideLoading();
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }
  retriveClaimSubmissionId() {
    this.sharedService.showLoading();
    this.apiService
      .post('api/integration/courtex/generic-api', {
        path: `/api/v2/claims/zero_zero_id/${this.package.claimId}`,
        method: 'GET',
        input: {},
      })
      .subscribe({
        next: (response) => {
          console.log({ response });
          if (response?.success == 'true') {
            this.package.submissionId =
              response?.data?.data?.formio_submission_id;
          } else {
            this.formSubmissionData = {
              data: {
                metadata: {
                  zero_zero_id: this.package.claimId,
                  case_id: this.package.caseId,
                },
              },
            };
          }

          this.prepareSubmissionForm().then(() => {
            if (this.package.submissionId) {
              this.loadSubmissionData();
            } else {
              this.formDefinistionSubmissionData = {
                data: {
                  metadata: {
                    zero_zero_id: this.package.claimId,
                    case_id: this.package.caseId,
                  },
                },
              };
              this.onSaveClick(true, false, true);
            }
          });
        },
        error: (err) => {
          console.log({ err });
          this.sharedService.hideLoading();
          this.prepareSubmissionForm();
          //TODO: set default zero_zero_id as claim id, as API returns 404 error
          this.formSubmissionData = {
            data: {
              metadata: {
                zero_zero_id: this.package.claimId,
                case_id: this.package.caseId,
              },
            },
          };
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }

  prepareSubmissionForm() {
    return new Promise((resolve, reject) => {
      this.formIOStage = FormIOStage.STAGE2;
      this.formioSubmissionForm = `${environment.formio.baseUrl}${this.package.formioName}`;
      if (this.package.submissionId) {
        // this.formioSubmissionForm = `${environment.formio.baseUrl}${this.package.formioName}/submission/${this.package.submissionId}`;
      }
      this.formioSubmissionForm = `${this.formioSubmissionForm}`;
      this.apiService
        .formGet(`${this.formioSubmissionForm}`, {
          'X-Jwt-Token': `${this.sharedService.loginUser().formio_jwt}`,
        })
        .subscribe({
          next: (formDefinition) => {
            console.log({ formDefinition });
            formDefinition = this.removeValidation(formDefinition);
            formDefinition.action = null;
            // store the definition on this controller for use by the template
            this.formDefinition = formDefinition;
            // this.formDefinistionSubmissionData = {
            //   data: {
            //     metadata: {
            //       zero_zero_id: this.package.claimId,
            //       case_id: this.package.caseId,
            //     },
            //   },
            // };
            // if(this.package.submissionId) {
            //   this.loadSubmissionData();
            // }
          },
          error: (err) => {
            console.log({ err });
            reject(false);
          },
          complete: () => {
            console.log('prepareSubmissionForm completed');
            resolve(true);
          },
        });
    });
  }
  loadSubmissionData() {
    this.apiService
      .formGet(
        `${environment.formio.baseUrl}${this.package.formioName}/submission/${this.package.submissionId}`,
        {
          'X-Jwt-Token': `${this.sharedService.loginUser().formio_jwt}`,
        }
      )
      .subscribe({
        next: (formDefinistionSubmissionData) => {
          this.formDefinistionSubmissionData = formDefinistionSubmissionData;
          this.formDefinition.readOnly = !this.formDefinition.readOnly;

          this.onSaveClick(true, false, true);
        },
        error: (err) => {
          console.log({ err });
        },
        complete: () => {
          console.log('loadSubmissionData completed');
        },
      });
  }

  onSubmit() {
    if (this.formIOStage == FormIOStage.STAGE2) {
      this.formIOAPICallStage = FormIOAPICallStage.STARTED;
      this.triggerFormioSubmit(true);
    }

    const interval = setInterval(() => {
      if (this.formIOAPICallStage == FormIOAPICallStage.COMPLETED) {
        clearInterval(interval); // Stop the interval
        this.onSubmitAfterSave();
        // Call your second function logic here
      } else if (this.formIOAPICallStage == FormIOAPICallStage.FAILED) {
        this.sharedService.showToastMessage({
          message: 'Submission failed',
          type: MessageType.error,
        });
        clearInterval(interval);
      }
    }, 100); // Check the flag every 100 milliseconds
  }
  onSubmitAfterSave() {
    // this.onSaveClick(true);
    this.preventSaveCallOnDestroy = true;
    this.sharedService.showLoading();

    this.package.percentageDocExtraction = this.percentage.toString(); //update percentage

    const timer = this.sortHeadComponent.timer;
    this.package.timeForDocExtraction =
      this.decimalPipe.transform(timer.hours, '2.0') +
      ':' +
      this.decimalPipe.transform(timer.minutes, '2.0') +
      ':' +
      this.decimalPipe.transform(timer.seconds, '2.0'); //update time

    this.sharedService.selectedPackage.set(this.package);
    // this.package.status = 'COMPLETED';
    let packageTemp = JSON.parse(JSON.stringify(this.package));
    delete packageTemp.activityDTOs;
    delete packageTemp.anPackageDocumentDTOs;
    delete packageTemp.anPackageDocumentTypeDTOs;
    this.apiService
      .put(`package/${this.package.packageId}/submit`, packageTemp)
      .subscribe({
        next: (respose) => {
          console.log({ respose });
          if (respose.success == 'true') {
            this.sharedService.showToastMessage({
              message: 'Thank you! Your submission was successful.',
              type: MessageType.success,
            });
            this.router.navigate(['/packages', '1']);
          } else {
            this.sharedService.showToastMessage({
              message:
                respose.message ||
                'Document processing failed. Please submit again',
              type: MessageType.error,
            });
          }
        },
        error: (err) => {
          this.sharedService.showToastMessage({
            message:
              'Document processing encountered an error. Please resubmit.',
            type: MessageType.error,
          });
          this.sharedService.hideLoading();
        },
        complete: () => {
          this.sharedService.hideLoading();
        },
      });
  }
  showCopiedNotification() {
    this.isShowCopiedNotification = true;
    setTimeout(() => {
      this.isShowCopiedNotification = false;
    }, 1000);
  }
  showZoomLevel(val: number) {
    return Math.floor(val * 100);
  }

  get FormIOStage() {
    return FormIOStage;
  }
  triggerFormioSubmit(isSubmitForm: boolean) {
    const formIOEl: HTMLElement = this.formioboxEl.nativeElement as HTMLElement;
    if (formIOEl) {
      const btn = formIOEl.querySelector(
        'button[type="submit"]'
      ) as HTMLButtonElement;
      if (btn && isSubmitForm) {
        btn.click();
      }
    }
  }
  onSaveClick(hideToast: boolean, isBack: boolean, isSubmitForm: boolean) {
    if (
      this.isArchived ||
      this.sharedService.readOnlyMode() ||
      this.package.status == 'COMPLETED' ||
      this.package.status == 'CANCELLED'
    ) {
      return;
    }
    // if (this.submissionFormioObject) {
    //   console.log(this.submissionFormioObject.submission);
    //   this.handleBeforeSubmit(
    //     this.submissionFormioObject.submission,
    //     function (error, submission) {}
    //   );
    //   // this.submissionFormioObject.;
    // }
    if (this.formIOStage == FormIOStage.STAGE2) {
      this.triggerFormioSubmit(isSubmitForm);
    }
    this.sharedService.showLoading();

    this.package.percentageDocExtraction = this.percentage.toString(); //update percentage

    const timer = this.sortHeadComponent.timer;
    this.package.timeForDocExtraction =
      this.decimalPipe.transform(timer.hours, '2.0') +
      ':' +
      this.decimalPipe.transform(timer.minutes, '2.0') +
      ':' +
      this.decimalPipe.transform(timer.seconds, '2.0'); //update time

    // this.sharedService.selectedPackage.set(this.package);
    let packageTemp = JSON.parse(JSON.stringify(this.package));
    delete packageTemp.activityDTOs;
    delete packageTemp.anPackageDocumentDTOs;
    delete packageTemp.anPackageDocumentTypeDTOs;
    this.apiService.put('package', packageTemp).subscribe({
      next: (respose) => {
        if (respose?.success == 'true') {
          if (!hideToast) {
            this.sharedService.showToastMessage({
              message: 'Your edits have been saved successfully',
              type: MessageType.success,
            });
          }
          if (isBack) {
            if (this.isCourtexView) {
              this.router.navigateByUrl(`/dc/${this.packageDBId}`);
            } else {
              if (this.sharedService.readOnlyMode()) {
                this.router.navigateByUrl(
                  `/packages/${this.packageDBId}/classification/readonly`
                );
              } else {
                this.router.navigateByUrl(
                  `/packages/${this.packageDBId}/classification`
                );
              }
            }
          }
        }
      },
      error: (err) => {
        this.sharedService.showToastMessage({
          message: 'Error' || err.message,
          type: MessageType.error,
        });
        this.sharedService.hideLoading();
      },
      complete: () => {
        if(!this.disableButtons)
        this.sharedService.hideLoading();
      },
    });
  }

  movePreview(next: boolean) {
    // Reset Rotation
    this.currentRotateState = 0;
    // Reset Zoom leve
    this.zoomLevel = 1;

    let { docIndex, fileIndex, pageIndex } = this.previewImage;
    let currentDoc = docIndex;
    let currentFile = fileIndex;
    let currentImage = '';

    let flattenIndex = this.flattenArray.findIndex(
      (item: any) =>
        item.docIndex === docIndex &&
        item.fileIndex === fileIndex &&
        item.pageIndex === pageIndex
    );
    if (next) {
      flattenIndex++;
    } else {
      flattenIndex--;
    }
    if (flattenIndex > -1 && flattenIndex <= this.flattenArray.length - 1) {
      let flattenObj = this.flattenArray[flattenIndex];
      currentDoc = flattenObj.docIndex;
      currentFile = flattenObj.fileIndex;
      pageIndex = flattenObj.pageIndex;
      currentImage = flattenObj.image;
      this.package.anPackageDocumentTypeDTOs[currentDoc].isExpand = true;
      this.package.anPackageDocumentTypeDTOs[currentDoc].files[
        currentFile
      ].isExpand = true;
      this.selectedDoc =
        this.package.anPackageDocumentTypeDTOs[currentDoc].name;
      this.formPreviewImage(
        currentDoc,
        currentFile,
        pageIndex,
        currentImage,
        flattenObj.pageCount
      );
    }
  }

  formPreviewImage(
    docIndex: number,
    fileIndex: number,
    pageIndex: number,
    url: string,
    selectedPage?: number
  ) {
    // Reset Rotation
    this.currentRotateState = 0;
    // Reset Zoom leve
    this.zoomLevel = 1;
    if (selectedPage) {
      this.selectedPage = selectedPage;
    } else {
      let flattenObj = this.flattenArray.find(
        (item: any) =>
          item.docIndex === docIndex &&
          item.fileIndex === fileIndex &&
          item.pageIndex === pageIndex
      );
      this.selectedPage = flattenObj ? flattenObj.pageCount : 1;
    }
    this.selectedDoc = this.package.anPackageDocumentTypeDTOs[docIndex].name;
    let packageBaseUrl = 'package';
    if(this.isArchived) {
      packageBaseUrl = 'package/archived'
    }
    const baseURL =
      this.baseUrl +
      `${packageBaseUrl}/document/get-by-package/download/` +
      this.package.id +
      '/';
    if (docIndex > -1 && fileIndex > -1 && pageIndex > -1 && url) {
      this.previewImage = {
        docIndex,
        fileIndex,
        pageIndex,
        url: baseURL + url,
      };
    }
  }

  navigateToDC() {
    this.preventSaveCallOnDestroy = true;
    this.package.page = '';
    this.onSaveClick(true, true, true);

    // if (
    //   this.isArchived ||
    //   this.sharedService.readOnlyMode() ||
    //   this.package.status == 'COMPLETED' ||
    //   this.package.status == 'CANCELLED'
    // ) {
    // }
    let packageBaseUrl = 'packages';
    if (this.isArchived) {
      packageBaseUrl = 'archived-packages';
    }
    if (this.sharedService.userview() == 'userview') {
      this.router.navigateByUrl(
        `/${packageBaseUrl}/${this.sharedService.packageListPageNumber()}/userview`
      );
    } else {
      this.router.navigateByUrl(
        `/${packageBaseUrl}/${this.sharedService.packageListPageNumber()}`
      );
    }
  }
  onFormIoValueChange(data: any) {
    console.log({ data });

    if (data?.data) {
      // this.formSubmissionData = data;
      this.calculatePercentage(data.data);
    }
  }
  // beforeSubmitSubmissionForm(
  //   submission: object,
  //   callback: FormioSubmissionCallback
  // ) {
  //   console.log({ submission });
  //   this.apiService
  //     .formPost(`${this.formioSubmissionForm}`, JSON.stringify(submission), {
  //       'X-Token': `${environment.formio.noValidate}`,
  //       // 'X-Jwt-Token': `${this.sharedService.loginUser().formio_jwt}`,
  //       'Content-Type': 'application/json',
  //     })
  //     .subscribe({
  //       next(response) {
  //         console.log({ response });
  //       },
  //       error(err) {
  //         console.log({ err });
  //       },
  //       complete() {
  //         console.log('complete');
  //       },
  //     });
  // }
  // onBeforeSubmit(data: any) {
  //   console.log({ data });
  //   return false;
  // }

  /**
   * A helper method to remove validation from the form.io submission object
   */
  removeValidation(obj: any): any {
    // Remove these keys from every object in the tree
    const keysToRemove = ['validate', 'required', 'minYear', 'maxYear'];

    // Check if the input is an array
    if (Array.isArray(obj)) {
      return obj.map((item) => this.removeValidation(item));
    }
    // Check if the input is an object
    else if (typeof obj === 'object' && obj !== null) {
      return Object.keys(obj).reduce((newObj: any, key) => {
        if (!keysToRemove.includes(key)) {
          // Recursively apply the function to nested objects or arrays
          newObj[key] = this.removeValidation(obj[key]);
        }
        return newObj;
      }, {});
    }
    // Return the value directly if it's neither an object nor an array
    else {
      return obj;
    }
  }

  async handleBeforeSubmit(
    submission: any,
    callback: FormioSubmissionCallback
  ) {
    if (this.sharedService.readOnlyMode()) return;
    if (this.package.status == 'COMPLETED') {
      this.sharedService.showToastMessage({
        message:
          'Package already submitted to Courtex. Cannot save form data again.',
        type: MessageType.info,
      });
      this.formDefinition.readOnly = !this.formDefinition.readOnly;
      return;
    }

    // TODO: replace with your actual value (only when you receive a submission ID from the 'get claim details by zero zero id' endpoint)
    let EXISTING_SUBMISSION_ID = this.package.submissionId;

    // determine the courtex URL to post to for this request
    const courtexUrl = EXISTING_SUBMISSION_ID
      ? `/api/v1/formio/submission/${EXISTING_SUBMISSION_ID}?form_url=${this.formioSubmissionForm}`
      : `/api/v1/formio/submission?form_url=${this.formioSubmissionForm}`;
    this.sharedService.showLoading();
    this.apiService
      .post(`api/integration/courtex/generic-api`, {
        path: courtexUrl,
        method: 'POST',
        input: submission,
        headers: {
          // authentication for the form.io api
          'X-Jwt-Token': this.sharedService.loginUser().formio_jwt,
          // Authorization: this.sharedService.loginUser().courtex_jwt,
        },
      })
      .subscribe({
        next: (response) => {
          this.disableButtons = false;
          // this.sharedService.hideLoading();
          console.log({ response });
          if (typeof response.data === 'object') {
            this.formDefinition.readOnly = !this.formDefinition.readOnly;

            this.isSubmitButtonEnabled = true;
            this.sharedService.showToastMessage({
              message: 'Successfully saved',
              type: MessageType.success,
            });
            this.package.submissionId = response.data._id;
            this.onSaveClick(true, false, false);
            this.formIOAPICallStage = FormIOAPICallStage.COMPLETED;
            callback(null as any, response.data);
          } else {
            // Error
            this.sharedService.showToastMessage({
              message: `Error: ${
                response.message || 'Form submission failed. Please try again!'
              }`,
              type: MessageType.error,
            });
            this.formIOAPICallStage = FormIOAPICallStage.FAILED;
            callback(
              {
                message: `Form IO error ${response.data}`,
                component: null as any,
              },
              {}
            );
          }
        },
        error: (err) => {
          this.disableButtons = false;
          this.sharedService.hideLoading();
          console.log({ err });
          callback(err as any, {});
          this.formIOAPICallStage = FormIOAPICallStage.FAILED;
        },
        complete: () => {
          this.disableButtons = false;
          console.log('submission form completed');
          this.formIOAPICallStage = FormIOAPICallStage.COMPLETED;
        },
      });
  }

  addClickEvent(field: any) {
    const fieldName = field?.target as HTMLInputElement;
    if (
      fieldName &&
      fieldName.tagName == 'INPUT' &&
      (fieldName.type == 'text' || fieldName.type == 'textarea')
    ) {
      this.selectedElement = fieldName;
      this.setSelectedElement(this.selectedElement);
    }
  }

  public get CursorMode() {
    return CursorMode;
  }
  toggleExtractCursor() {
    if (this.cursorMode == CursorMode.EXTRACT) {
      this.cursorMode = CursorMode.ZOOM;
    } else {
      this.cursorMode = CursorMode.EXTRACT;
    }
  }
}