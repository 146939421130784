@if(showPopup) {
<div #activityPopup class="overlay" (click)="closeActivityLog()">
    <div class="popup" (click)="$event.stopPropagation()">
        <div class="d-flex header">
            <button class="btn export-button" (click)="downloadCsv()">Export</button>
        </div>
        <div class="close" (click)="closeActivityLog()">&times;</div>
        <div class="content">
            <div class="activity-grid">
                <div class="grid-header gap-20"># </div>
                <div class="grid-header gap-20">Subject</div>
                <div class="grid-header">Date & time </div>
                <div class="grid-header">User</div>
                <div class="grid-header">TAT (hh:mm)</div>

                @for(log of activityLogs; track log; let idx = $index; let last = $last) {
                <div class="gap-20" [ngClass]="{'no-border': last}">{{idx + 1}}</div>
                <div class="gap-20" [ngClass]="{'no-border': last}">{{log.message}}</div>
                <div [ngClass]="{'no-border': last}">{{log.createdDateTime | formatDateTime}}</div>
                <div [ngClass]="{'no-border': last}">{{log.createdBy}}</div>
                <div [ngClass]="{'no-border': last}">{{log.tat}}</div>
                }
                @empty {
                <div class="no-records">No records</div>
                }

            </div>
            <!-- <div class="total-hours">07:30 (hh:mm)</div> -->
        </div>
    </div>
</div>
}