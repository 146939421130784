<div class="report-header" *ngIf="reportName">{{reportName}}</div>
<div class="tab-container">

    <div class="tab-head-group">
        <div class="tab-head" [class.active]="activeTab == 'table'" (click)="activeTab = Tab.Table;">
            Table
        </div>
        <div class="tab-head"  *ngIf="reportId !== '1'" [class.active]="activeTab == 'pivot'" (click)="activeTab = Tab.Pivot;">
            Pivot table
        </div>
    </div>
    <div class="tab-body-group">
        <div class="tab-body pivot-table" *ngIf="activeTab == 'pivot'">
            <bi-app id="biApp" [pageDefinition]="pageDefinition" [baseUrl]="baseURL" [authDefinition]="authObject" ></bi-app>
        </div>
        <div class="tab-body" *ngIf="activeTab == 'table'">
            <div class="Ang-dashboard-container">

                @if(reportId != '1') {
                    @if (isInitialized && filterList.length) {
                        <app-dashboard-filter (emitApplyFilter)="applyFilter($event)" (emitResetFilter)="resetFilter()"
                            [filterList]="filterList">
                        </app-dashboard-filter>
                    }
                <div>
                    <bi-app id="biApp" [pageDefinition]="tableDefinition" [baseUrl]="baseURL" [authDefinition]="authObject" (postApiCompleted)="handleApi($event?.type === 'error')" >
                    </bi-app>
                </div>
                }
                @if(reportId == '1') {
                    <div class="prod-report-container">
                        <app-productivity-report [coderNameList]="coderNameList"></app-productivity-report>
                    </div>
                }
            </div>
        </div>
    </div>
</div>