import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDashboardResponse, IResponse } from '../_interfaces/entity';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) { }
  get(url: string) {
    return this.http.get<IResponse>(this.baseUrl + url);
  }

  post(url: string, data?: any) {
    return this.http.post<IResponse>(this.baseUrl + url, data);
  }

  put(url: string, data?: any) {
    return this.http.put<IResponse>(this.baseUrl + url, data);
  }

  delete(url: string, data?: any) {
    return this.http.delete<IResponse>(this.baseUrl + url, data);
  }
  login(url: string, data?: any) {
    return this.http.post<IResponse>(this.baseUrl + url, data, {
      headers: { 'x-api-key': environment.xApiKey },
    });
  }

  formGet(url: string, header?: any) {
    return this.http.get<any>(url, {
      headers: header,
    });
  }
  formPost(url: string, data?: any, header?: any) {
    return this.http.post<IResponse>(url, data, {
      headers: header,
    });
  }

  dashboardPost(url: string, data?: any) {
    return this.http.post<IDashboardResponse>(url, data);
  }

  exportPost(url: string) {
    const options = {
      headers: new HttpHeaders({
        'Accept': 'application/octet-stream'
      }),
      responseType: 'blob' as 'json' // Set responseType to 'blob' to handle binary data
    };

    return this.http.post<Blob>(this.baseUrl + url, '', {
      responseType: 'blob' as 'json'
    });
  }


}

