import { inject, Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../_services/shared.service';

@Pipe({
  name: 'status',
  standalone: true,
})
export class StatusPipe implements PipeTransform {
  sharedService = inject(SharedService);
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) {
      const status = this.sharedService.status().find(item => item.id === value);
      return status?.value || '';
    }
    return '';
  }
}
