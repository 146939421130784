import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './_components/_common/header/header.component';
import { FooterComponent } from './_components/_common/footer/footer.component';
import { MenuComponent } from './_components/_common/menu/menu.component';
import { LoadingComponent } from './_components/_common/loading/loading.component';
import { ToastMessageComponent } from './_components/_common/toast-message/toast-message.component';
import { LoginComponent } from './_components/login/login.component';
import { environment } from '../environments/environment';
import { Subscription, take, timer } from 'rxjs';
import { SharedService } from './_services/shared.service';
import { MessageType } from './_interfaces/entity';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    LoadingComponent,
    ToastMessageComponent,
    LoginComponent,
    CommonModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  currentApplicationVersion = `${environment.appVersion}${environment.env}`;
  @HostBinding('attr.app-version')
  appVersionAttr = `${environment.appVersion}${environment.env}`;
  title = 'Angeion';
  router = inject(Router);
  sharedService = inject(SharedService);

  private intervalTime = 1000 * 60 * 15; // 2 min //  1000 * 60 * 2
  private interval$ = timer(this.intervalTime);
  private subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer(): void {
    this.subscription = this.interval$.pipe(take(1)).subscribe(() => {
      this.logout();
    });
  }

  resetTimer(): void {
    // Unsubscribe from the previous timer
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // Start a new timer
    this.startTimer();
  }

  private logout(): void {
    console.log('Timer expired!');
    this.sharedService.loginUser.set({
      courtex_jwt: '',
      formio_jwt: '',
      user: {
        first_name: '',
        last_name: '',
        role: '',
        id: 0,
      },
    });
    localStorage.removeItem(this.sharedService.LOGIN_USER_SESSION_NAME);
    this.sharedService.showToastMessage({
      message: 'Your session has ended. Please attempt to login again',
      type: MessageType.info,
      stayActive: true,
    });
    this.router.navigate(['/login']);
  }
}
