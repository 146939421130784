import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SharedService } from '../../../_services/shared.service';
import { ILoginUser } from '../../../_interfaces/user';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule, CommonModule, ConfirmPopupComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  userName: string = 'John Doe';
  showMenu: boolean = false;
  sharedService = inject(SharedService);
  router = inject(Router);
  showConfirmation: boolean = false;

  ngOnInit(): void {
    this.loadLoginUserOnRefresh();
  }

  logout() {
    this.sharedService.loginUser.set({
      courtex_jwt: '',
      formio_jwt: '',
      user: {
        first_name: '',
        last_name: '',
        role: '',
        id: 0
      },
    });
    this.sharedService.logout.next(true);
    this.sharedService.redirectURL = this.router.url;
    localStorage.removeItem(this.sharedService.LOGIN_USER_SESSION_NAME);
    this.router.navigate(['/login']);
  }

  loadLoginUserOnRefresh() {
    if (!this.sharedService.loginUser().courtex_jwt) {
      const userData: ILoginUser = JSON.parse(
        localStorage.getItem(
          this.sharedService.LOGIN_USER_SESSION_NAME
        ) as string
      ) as ILoginUser;
      this.sharedService.loginUser.set(userData);
      localStorage.setItem(
        'formioToken',
        this.sharedService.loginUser()?.formio_jwt
      );
    }
  }
}
