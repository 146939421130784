<div class="container">
    <div class="head">
        <app-sort-head [headType]="SortHeadType.FOI" [package]="package" (onSubmit)="onSubmit()"
            (emitSaveCall)="preventSaveCallOnDestroy = true;" (onSave)="this.package.page = 'FOI'; disableButtons = true; onSaveClick(false, false, true)"
            [percentage]="percentage" [timerSaved]="package.timeForDocExtraction" (onBackClick)="navigateToDC()"
            [isGroupingProgress]="disableButtons"
            [isSubmitButtonEnabled]="isSubmitButtonEnabled">
        </app-sort-head>
    </div>
    <div class="body">
        <div class="page-title">
            <div class="title">Fields of interest</div>
            <div class="underline"></div>
        </div>
        <div class="content">
            <div class="one doctype-tree">
                @for (doctype of package.anPackageDocumentTypeDTOs; track doctype.id; let docIndex=$index) {
                <div class="doctype-box" *ngIf="doctype.files?.length">
                    <div class="doctype-head-box">
                        <svg class="icon25" [class.close]="!doctype.isExpand"
                            (click)="doctype.isExpand=!doctype.isExpand">
                            <use xlink:href="assets/icon-sprite.svg#down" />
                        </svg>
                        <div class="doctype-head">{{doctype.name}} <span>({{doctype?.files?.length}}) </span></div>
                    </div>
                    <div class="doctype-body" *ngIf="doctype.isExpand">
                        @for (file of doctype.files; track file.name; let fileIndex=$index) {
                        <div class="file-box">
                            <div class="file-head-box">
                                <svg class="icon25" [class.close]="!file.isExpand"
                                    (click)="file.isExpand=!file.isExpand">
                                    <use xlink:href="assets/icon-sprite.svg#down" />
                                </svg>
                                <div class="file-head">{{file.name}} <span>({{file?.images?.length}}) </span></div>
                            </div>
                            <div class="file-body" *ngIf="file.isExpand">
                                @for (image of file.images; track image; let pageIndex=$index) {
                                <img [src]="baseUrl + (isArchived ? 'package/archived' : 'package') + 
                           '/document/get-by-package/download/'+package.id+'/'+image"
                                    alt="" (click)="formPreviewImage(docIndex, fileIndex, pageIndex, image);"
                                    [class.active]="previewImage?.url==baseUrl+'package/document/get-by-package/download/'+package.id+'/'+image"
                                    (contextmenu)="$event.preventDefault();">
                                }
                            </div>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
            <div class="two preview">
                <div class="preview-head">
                    <div class="left-head">
                        <div class="extract"
                            [class.active]="cursorMode == CursorMode.EXTRACT"
                            (click)="toggleExtractCursor()"
                            [title]="'You have the option to extract text from the thumbnails at this location. Simply select the text box of a form field, choose the desired text from the page and the selected text will be transferred into the designated text box.'">
                            Extract
                        </div>
                        <div class="border"></div>
                        <div class="zoom-box flex-box">
                            <div class="minus" (click)="zoomOut()">-</div>
                            <div class="value">{{showZoomLevel(zoomLevel)}}%</div>
                            <div class="plus" (click)="zoomIn()">+</div>
                        </div>
                        <div class="border"></div>
                        <div class="refresh">
                            <svg class="icon" (click)="rotateProcess()">
                                <use xlink:href="assets/icon-sprite.svg#rotate" />
                            </svg>
                        </div>
                        <div class="border"></div>
                        <div class="page-nav d-flex" *ngIf="package?.anPackageDocumentDTOs?.length">
                            <svg class="icon" (click)="movePreview(false)">
                                <use xlink:href="assets/icon-sprite.svg#arrow-prev" />
                            </svg>
                            <div class="d-flex zoom-box">{{selectedPage}}</div>
                            <div>of {{package.anPackageDocumentDTOs.length || 0}}</div>
                            <svg class="icon" (click)="movePreview(true)">
                                <use xlink:href="assets/icon-sprite.svg#arrow-next" />
                            </svg>
                        </div>
                    </div>
                    <div class="right-head">
                        {{selectedDoc}}
                    </div>
                </div>
                <div class="preview-box" #previewBox>
                    @if (isShowCopiedNotification) {
                    <div class="copy-notification">Copied to clipboard </div>
                    }
                    <app-image [selectedImage]="previewImage?.url" [desiredWidth]="previewWidth" [zoomLevel]="zoomLevel"
                        [rotate]="rotations[currentRotateState]" [cursorMode]="cursorMode" (onOutput)="onTextSelect($event)"></app-image>
                </div>
            </div>
            <div class="three fields" #formiobox>
                @if(formIOStage == FormIOStage.STAGE1 && !sharedService.readOnlyMode()){
                <h3>Lookup form</h3>
                <formio [src]='formioLookupForm' [submission]="formLookupData" 
                    (submit)="retriveCaseFormId($event)"></formio>
                } @else if(formIOStage == FormIOStage.STAGE2){
                <h3>{{submissionFormTitle || 'Submission form'}}</h3>
                <!-- <formio [src]='formioSubmissionForm' saveDraf (ready)="setEventListerner($event)"
                    [submission]="formSubmissionData" (submit)="onFinalFormSubmit($event)" (change)="onFormIoValueChange($event)"></formio> -->
                    @if(formDefinition.readOnly){
                        <formio [form]='formDefinition'  [submission]="formDefinistionSubmissionData" (ready)="setEventListerner($event)"  (click)="addClickEvent($event)"></formio>
                    } @else {
                        <formio [form]='formDefinition'  [submission]="formDefinistionSubmissionData" (ready)="setEventListerner($event)"  (click)="addClickEvent($event)"></formio>
                    }
                }


            </div>
        </div>
    </div>
</div>