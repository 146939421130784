export interface PackageList {
  // packageId: string,
  // source: string,
  // caseId: string,
  // status: string,
  // dateTime: string,
  // updatedTime: string,
  // aging: string,
  // coder: string,
  // claimID: string,

  id: string;
  createdDateTime: string;
  createdBy: string;
  updatedBy: string;
  updatedDateTime: string;
  packageId: string;
  caseId: string;
  claimId: string;
  sourceSystem: string;
  status: string;
  coderName: string;
  coderId: string;
  aging: string;
  lastUpdatedBy: string;
  percentageOfDocClassification: number;
  percentageDocExtraction: number;
  timeForDocClassification: string;
  timeForDocExtraction: string;
  caseName: string;
  isClassified: boolean;
}

export interface IFilter {
  caseName: string;
  caseId: string;
  claimId: string;
  coderName: string;
  coderId: IDropdown;
  packageId: string;
  createdDateFrom: string;
  createdDateTo: string;
  status: IDropdown;
  query: string;
  startIndex: number;
  pageSize: number;
  sourceSystem: string;
}
export interface IFilterPreset {
  id: number;
  name: string;
  filter: IFilter;
}
export interface IDropdown {
  id: string;
  value: string;
}

export enum DropdownSize {
  Small = 'Small',
  Normal = 'Normal',
  Big = 'Big',
}

export enum Status {
  NEW = 'New',
  WORK_IN_PROGRESS = 'Work in progress',
  ON_HOLD = 'On hold',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export interface IPackage {
  id: string;
  createdDateTime: string;
  createdBy: string;
  updatedBy: string;
  updatedDateTime: string;
  packageId: string;
  caseId: string;
  claimId: string;
  sourceSystem: string;
  status: string;
  coderName: string;
  coderId: string;
  lastUpdateDate: string;
  lastUpdatedBy: string;
  percentageOfDocClassification: string;
  percentageDocExtraction: string;
  timeForDocClassification: string;
  timeForDocExtraction: string;
  activityDTOs: IActivityDTO[];
  anPackageDocumentDTOs: IAnPackageDocumentDTO[];
  anPackageDocumentTypeDTOs: IAnPackageDocumentTypeDTOs[];
  caseName: string;
  submissionId: string;
  isClassified: boolean;
  formioId: string;
  formioName: string;
  page: string;
  packageEvent: string;
}

export interface IActivityDTO {
  id: string;
  createdDateTime: string;
  createdBy: string;
  updatedBy: string;
  updatedDateTime: string;
  message: string;
  packageId: string;
  packageUniqueId: string;
  tat: string;
}

export interface IAnPackageDocumentDTO {
  id: string;
  createdDateTime: string;
  createdBy: string;
  updatedBy: string;
  updatedDateTime: string;
  packageId: string;
  packageUniqueId: string;
  isClassified: boolean;
  isExtracted: boolean;
  percentageOfCompletion: number;
  documentType: string;
  fileName: string;
  caseId: string;
  content: string;
  isProcessed: boolean;
  imageUrl: string;
}

export interface IAnPackageDocumentTypeDTOs {
  category: string;
  createdBy: string;
  createdDateTime: string;
  files: IDocFile[];
  id: string;
  name: string;
  packageUniqueId: string;
  subCategory: string;
  updatedBy: string;
  updatedDateTime: string;
  isExpand?: boolean;
}

export interface IDocFile {
  name: string;
  images: string[];
  isExpand?: boolean;
}