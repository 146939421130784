<div class="login-container">
    <div class="background-1"></div>
    <div class="form-wrapper">
        <div class="img">
            <img src="../../../assets/images/login-img.png">
            <div class="img-logo logo">UST SmartOps&trade;</div>
        </div>
        <div class="form">
            <form (submit)="onSubmit()">
                <div class="logo">UST SmartOps&trade;</div>
                <div class="header"><span>Hello,</span> <br /> Welcome back</div>
                <div class="form-controls">
                    <label for="username" for="username">Username</label>
                    <input class="input" id="username" placeholder="Enter your username" name="username"
                        [(ngModel)]="username" />
                    <label for="password" for="password">Password</label>
                    <div class="input password-wrapper">
                        <input class="input password" id="password" [attr.type]="isPasswordVisible ? 'text': 'password'"
                            placeholder="Enter your password" name="password" [(ngModel)]="password" />
                        <svg class="eye-icon cur-pointer" (click)="isPasswordVisible = !isPasswordVisible">
                            <use attr.xlink:href="assets/icon-sprite.svg#{{isPasswordVisible? 'eye-open': 'eye'}}" />
                        </svg>
                    </div>


                    <!-- <div class="user-pref">
                        <div class="d-flex">
                            <input id="remember" type="checkbox">
                            <label for="remember" class="cur-pointer d-flex">Remember me</label>
                        </div>
                        <span>Forgot password?</span>
                    </div> -->
                    <button class="btn btn-login w-100">Sign in</button>
                </div>
            </form>
        </div>
    </div>
    <div class="version">
        <app-footer [appVersion]="currentApplicationVersion"></app-footer>
    </div>
</div>