<div class="container">
    <div class="menu-box">
        @for (menu of menus; track menu.id) {
        <div class="tooltip" *ngIf="menu.isShow">
            <svg class="menu-icon" (click)="menu.name === 'Listing'? resetFilter() : '';" [routerLink]="menu.href"
                *ngIf="menu.isShow">
                <use attr.xlink:href="assets/icon-sprite.svg#{{menu.icon}}" />
            </svg>
            <span class="tooltiptext">{{menu.title}}</span>
        </div>
        }
    </div>
</div>