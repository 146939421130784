import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedService } from '../../../_services/shared.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard-filter',
  standalone: true,
  imports: [FormsModule, CommonModule],
  providers: [DatePipe],
  templateUrl: './dashboard-filter.component.html',
  styleUrl: './dashboard-filter.component.scss'
})
export class DashboardFilterComponent {
   @Input() title: string = '';
  @Input() filterList: string[] = ['Case Name'];
  @Output() emitApplyFilter = new EventEmitter<any>();
  @Output() emitResetFilter = new EventEmitter<any>();
  @Input() sourceName: string[] = []
    activeRoute = inject(ActivatedRoute);
    reportId: string = '';
  dateFrom!: string | null;
  dateTo!: string | null;
  caseName: string = '';
  caseList: any = [];
  sessionStored: boolean = false;
  maxDate!: string | null;
  maxFromDate!: string | null;
  datePipe = inject(DatePipe);
  valueSelected: any = '';

  sharedService = inject(SharedService);

  uniqueList: any = {};
  filterApplied: any = {};
  constructor(private cdr: ChangeDetectorRef) {
    this.resetDate();
  }

  ngOnInit() {
    
    this.reportId = this.activeRoute.snapshot.paramMap.get(
      'report-id'
    ) as string;
    const sources = localStorage.getItem('sources');
    if (sources) {
      if (this.reportId === '1' || this.reportId === '2') {
        const uniqueCols = JSON.parse(sources).angeionData_archive?.unique_columns;
        if (uniqueCols) {
          this.filterList.forEach(item => {
            this.uniqueList[item] = uniqueCols[item];
            console.log("unique",uniqueCols[item])
          })
        }
     }else{
      const uniqueCols = JSON.parse(sources).angeionData?.unique_columns;
        if (uniqueCols) {
          this.filterList.forEach(item => {
            this.uniqueList[item] = uniqueCols[item];
            console.log("unique",uniqueCols[item])
          })
        }
     }
      
    }
    this.cdr.detectChanges(); 
  }

  inputChange(list: any) {
    this.filterApplied[list] = this.valueSelected;
  }

  applyFilter() {
    const filterApplied = Object.assign({}, this.filterApplied, { dateFrom: this.dateFrom, dateTo: this.dateTo });
    console.log(filterApplied);
    this.emitApplyFilter.emit(filterApplied);
  }

  resetFilter() {
    this.dateTo='';
    this.dateFrom=''
    this.resetDate();
    this.caseName = '';
    this.valueSelected = '';
    this.emitResetFilter.emit();
  }

  resetDate() {
    this.maxFromDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.dateTo = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    let dt = new Date();
    dt.setMonth(dt.getMonth() - 3)

    this.dateFrom = this.datePipe.transform(dt, 'yyyy-MM-dd');
    this.maxDate = this.dateTo;
  }

  onChangeFromDate(event: any) {
    if (this.dateFrom) {
      let dt = new Date(this.dateFrom);
      dt.setMonth(dt.getMonth() + 3)
      if(dt > new Date()) {
        dt = new Date();
      }
      this.dateTo = this.datePipe.transform(dt, 'yyyy-MM-dd');
      this.maxDate = this.dateTo;
    }
  }


}
