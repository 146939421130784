import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SharedService } from '../../../_services/shared.service';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss',
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageSize: number = 20;
  @Input() totalPackageLength: number = 0;
  @Input() listCount: number = 0;
  @Output() loadPageEmit = new EventEmitter<number>();
  pageNavList: any[] = [];
  pageSelected: any = 1;
  maxVisibleSize = 7;
  totalPages: number = 0;
  sharedService = inject(SharedService);

  ngOnChanges() {
    this.pageNavList = this.loadPageArray(this.pageSelected);
  }

  ngOnInit() {
    this.pageSelected = this.sharedService.filter().startIndex + 1;
    const pageNumber = this.totalPackageLength / this.pageSize;
    // this.pageNavList = Array.from({ length: pageNumber }, (_, i) => i + 1);
    this.pageNavList = this.loadPageArray(this.pageSelected);
  }

  loadPageArray(currentPage: any) {
    let pages = [];
    this.totalPages = Math.max(
      Math.ceil(this.totalPackageLength / this.pageSize),
      1
    );
    const halfWay = Math.ceil(this.maxVisibleSize / 2);
    const isStart = currentPage <= halfWay;
    const isEnd = this.totalPages - halfWay < currentPage;
    const isMiddle = !isStart && !isEnd;
    let ellipsesNeeded = this.maxVisibleSize < this.totalPages;
    let i = 1;
    while (i <= this.totalPages && i <= this.maxVisibleSize) {
      let label;
      let pageNumber = this.calculatePageNumber(
        i,
        currentPage,
        this.maxVisibleSize,
        this.totalPages
      );
      let openingEllipsesNeeded = i === 2 && (isMiddle || isEnd);
      let closingEllipsesNeeded =
        i === this.maxVisibleSize - 1 && (isMiddle || isStart);
      if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
        label = '...';
      } else {
        label = pageNumber;
      }
      pages.push({
        label: label,
        value: pageNumber,
      });
      i++;
    }
    return pages;
  }

  calculatePageNumber(
    i: any,
    currentPage: any,
    paginationRange: any,
    totalPages: any
  ) {
    let halfWay = Math.ceil(paginationRange / 2);
    if (i === paginationRange) {
      return totalPages;
    } else if (i === 1) {
      return i;
    } else if (paginationRange < totalPages) {
      if (totalPages - halfWay < currentPage) {
        return totalPages - paginationRange + i;
      } else if (halfWay < currentPage) {
        return currentPage - halfWay + i;
      } else {
        return i;
      }
    } else {
      return i;
    }
  }

  loadPage(nextPage: number) {
    if (nextPage < 1 || nextPage > this.totalPages) {
      return;
    }
    this.pageSelected = nextPage;
    this.pageNavList = this.loadPageArray(nextPage);
    this.loadPageEmit.emit(nextPage);
  }
}


