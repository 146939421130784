<div class="dropdown" #dropdownContainer [style.width]="containerWidth">
    <div class="input-section" [class.highlight]="highlight" [class.disable]="isDisabled" [class]="dropdownSize" (click)="toggleDropdown($event)">
        <div class="placeholder" *ngIf="!selected.value">{{placeHolder}}</div>
        <div class="placeholder" *ngIf="selected.value"> 
            <span *ngIf="converttoCamelCase"[title]="selected.value">{{selected.value | camelCase1}}</span>
            <span *ngIf="!converttoCamelCase" [title]="selected.value">{{selected.value}}</span>
        </div>
        <svg class="dropdown-icon">
            <use xlink:href="assets/icon-sprite.svg#down" />
          </svg>
    </div>
    <div class="options " #dropdown *ngIf="isShowDropdown">
        <div class="filter" *ngIf="hasFilter && backupList.length > 9"> 
            <input type="search" placeholder="Search" name="filetr" class="search" [class]="dropdownSize" [(ngModel)]="filter" (ngModelChange)="onFilter()" #search>
        </div>
      
        <ng-container *ngIf="isShowOptionList()"> 
        <div class="option" [class]="dropdownSize" *ngFor="let item of list; let i=index;" [class.active]="item == selected" (click)="selectItem($event, item)">
            <span *ngIf="converttoCamelCase">{{item.value | camelCase1}}</span>
            <span *ngIf="!converttoCamelCase">{{item.value}}</span>
        </div>
        </ng-container>
        <div class="option load" *ngIf="isShowLoading && !isShowOptionList()">
            <div class="loading"></div>Loading ....
        </div>
    </div>
</div>